<template>
  <div class="popup" >
    <div class="bg" v-on:click="$emit('close')"></div>
    <div class="menu">
     <!-- <div class="close-btn" v-on:click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
        </svg>
      </div>-->
      <div class="title_1">
        <h2>{{title}}</h2>
      </div>
      <div class="question-container">
        <span>{{question}}</span>
      </div>
      <div class="button-container">
        <b-button   v-on:click="$emit('close')" class="action-btn">{{$t(`common.no-text`)}} </b-button>
        <b-button   v-on:click="$emit('confirm')" class="action-btn">{{$t(`common.yes-text`)}} </b-button>
      </div>
    </div>
  </div>

</template>

<script>

    export default {
      name: 'ConfirmPopup',
      props: {
        title: {
          type: String,
          default: ''
        },
        question: {
          type: String,
          default: ''
        }
      },
      data() {
        return {

        }
      },
      mounted() {

      },
      methods: {

      }
    }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .popup {
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .menu {
      font-size: get-size(18);
      position: absolute;
      // background-color: #FFFFFF;
      width: get-size(400);
      min-width: get-size(400);

      margin: auto;
      border-radius: get-size(10);
      margin-top: get-size(100);
      .title_1 {
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        padding-top: get-size(15);
        padding-bottom: get-size(15);
        h1 {
          font-size: get-size(35);
          font-style: normal;
          font-weight: 400;
          line-height: get-size(24);
          text-align: center;
          margin: 0;
          margin-top: get-size(13);
          margin-bottom: 0;
        }
        h2 {
          font-size: get-size(22);
          font-style: normal;
          font-weight: 400;
          line-height: get-size(24);
          text-align: center;
          margin: 0;
          margin-bottom: 0;
        }
        h3 {
          font-size: get-size(14);
          font-style: normal;
          font-weight: 400;
          line-height: get-size(24);
          text-align: center;
          margin: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .close-btn {
    position: absolute;
    right: get-size(5);
    top:get-size(13);
    font-size: get-size(20);
    cursor: pointer;
  }

  .question-container {
    margin-bottom: get-size(20);
  }

  .button-container {
    margin-bottom: get-size(20);
  }

  .action-btn {
    width: get-size(100);
    cursor: pointer;
    border-width: get-size(1);
    border-radius: get-size(5);
    padding: get-size(5) get-size(15) get-size(5) get-size(15);
    margin-right: get-size(20);
    font-size: get-size(18);
  }

  @media (max-width: 640px) {
    .popup .menu {
      width: get-size(400);
      min-width: get-size(400);
    }
  }

  @media (max-width: 400px) {
    .popup .menu {
      width: get-size(400);
      min-width: get-size(400);
    }
  }
</style>
