var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.length > 0)?_c('div',{staticClass:"main"},[_c('div',{class:{'row': true,'header-row': _vm.header}},[(_vm.header)?_vm._l((_vm.data),function(item,index){return _c('div',{key:index,class:{'table-field': true,
          'header-field': true,
          'address-field': (item.field === 'address' || item.field === 'serialNumber' || item.field === 'installationPlace' || item.field === 'personalAccount')},on:{"click":function($event){return _vm.setSort(item.field)}}},[_c('span',[_vm._v(_vm._s(item.value))]),(_vm.sort && _vm.sort.field == item.field)?_c('span',[(_vm.sort.ascDir)?_c('svg',{staticStyle:{"margin-left":"2px"},attrs:{"data-v-6242c37e":"","width":"0.5rem","height":"0.8rem","viewBox":"0 0 20 30","fill":"#999","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"data-v-6242c37e":"","d":"M10 0L10 20","stroke":"#999","stroke-width":"1.5"}}),_c('path',{attrs:{"data-v-6242c37e":"","d":"M19 11L10 20L1 11","stroke":"#666","stroke-width":"1.5"}})]):_c('svg',{staticStyle:{"margin-left":"2px"},attrs:{"data-v-6242c37e":"","width":"0.5rem","height":"0.8rem","viewBox":"0 0 20 30","fill":"#999","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"data-v-6242c37e":"","d":"M10 22L10 2","stroke":"#999","stroke-width":"1.5"}}),_c('path',{attrs:{"data-v-6242c37e":"","d":"M19 11L10 1.99999L1 11","stroke":"#1A1A1A","stroke-width":"1.5"}})])]):_vm._e()])}):_vm._l((_vm.data),function(item,index){return _c('div',{key:index,class:{'table-field': true,
        'number-field': item.type === 'number',
        'date-field': item.type === 'date',
        'string-field': item.type === 'string',
        'address-field': (item.field === 'address' || item.field === 'serialNumber' || item.field === 'installationPlace' || item.field === 'personalAccount')
        }},[_vm._v(_vm._s(item.value)+" ")])})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }