<template>
  <div  class="widget" v-if="fields && data">
    <div class="report_header">
      <report-item :sortQuery = "sortQuery" @setSort="sort => $emit('setSort', sort)" :header="true" :data="getHeaderValue"></report-item>
    </div>
    <div class="report_body">
      <report-item v-for="(item, index) in data"  :key="index" :data="getItem(item)"></report-item>
    </div>
    <div class="footer-container">

    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import MeterSelectWidget from '../common/MeterSelectWidget'
  import {APP_TABS} from '../../constant'
  import ReportItem from './ReportItem'

  export default {
    name: 'reportTable',
    components: {ReportItem},
    props: {
      sortQuery: {},
      fields: null,
      data: null,
      name: {
        type: String,
        default: ''
      }
    },
    computed: {
      getHeaderValue() {
        let result = []
        if (this.fields && this.fields.length > 0) {
          result = this.fields.map(field => {
            const obj = {}
            obj.value = field.label
            obj.type =  'header'
            obj.field = field.field
            return obj
          })
        }
        return result
      }
    },
    created() {
      window.addEventListener('resize', this.resizeHandler)
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    data() {
      return {
        loaded: false
      }
    },
    mounted() {

    },
    methods: {
      getItem(item) {
        const result = []
        this.fields.forEach(field => {
          const obj = {}
          obj.value = item[field.field]
          obj.type =  field.type
          obj.field = field.field
          result.push(obj)
        })
        return result
      },
      fetchData() {

      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "src/assets/scss/main";

  .widget {
    width: max-content;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    padding-top: get-size(10);
    padding-bottom: get-size(10);
    margin: auto;
  }

  .report_header {
    display: flex;
    width: max-content;
    min-width: max-content;
  }

  .report_body {
    display: flex;
    flex-direction: column;
    width: max-content;
    min-width: max-content;
    margin-bottom: get-size(10);
  }

  .info {
    opacity: 0.65;
  }

  hr {
    width: 100%;
  }

  @media (max-width: 850px) {
    .widget {
      width: max-content;
      min-width: max-content;
    }
  }

  @media (max-width: 640px) {
    .widget {
      width: max-content;
      min-width: max-content;
    }
  }

  @media (max-width: 400px) {
    .widget {
      width: max-content;
      min-width: max-content;
    }
  }
</style>
