<template>
  <div class="current-widget">
    <div class='main_header'>
      <div class="big">
        <span>{{$t(`widgets.connection.title_big`)}}</span>
      </div>
      <div class="small">
        <span>{{$t(`widgets.connection.title_small`)}}</span>
      </div>
    </div>
    <div class="chart-content">
      <template v-for="(item, index) in dataKeys.data">
        <VerticalLineChartItem
          :keyWidh="'18%'"
          :indicatorBgWidh="'58%'"
          :key="index"
          :keyVal="item.keyAlias"
          :value="item.value"
          :percent="item.percent"
          :indicatorColor="item.color"
          :clickable="true"
           @click="goOnDeviceView(CONNECTION_WIDGET_FILTER_VALUES[index])">
        </VerticalLineChartItem>
      </template>
    </div>
    <div class="data-content">
      <ChartDescriptionItem
        :iconColor="getColor('green_color')"
        :text="$t(`widgets.connection.description_good`)"
        :value="good"
        :percent="goodPercent"
        :clickable="true"
        @click="goOnDeviceView(CONNECTION_WIDGET_FILTER_VALUES[8])">
      </ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('blue_color')"
        :text="$t(`widgets.connection.description_medium`)"
        :value="normal"
        :percent="normalPercent"
        :clickable="true"
        @click="goOnDeviceView(CONNECTION_WIDGET_FILTER_VALUES[9])">
      </ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('red_color')"
        :text="$t(`widgets.connection.description_bad`)"
        :value="bad"
        :percent="badPercent"
        :clickable="true"
        @click="goOnDeviceView(CONNECTION_WIDGET_FILTER_VALUES[10])">
      </ChartDescriptionItem>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import VerticalLineChartItem from '../widget/util/VerticalLineChartItem'
  import ChartDescriptionItem from '../widget/util/ChartDescriptionItem'
  import {common} from '../../mixin/mixin.js'
  import {CONNECTION_WIDGET_FILTER_VALUES} from '../../apiTypeConstant'

  export default {
    name: 'ConnectionWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, VerticalLineChartItem, ChartDescriptionItem},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        CONNECTION_WIDGET_FILTER_VALUES,
        good: 0,
        normal: 0,
        bad: 0,
        goodPercent: 0,
        normalPercent: 0,
        badPercent: 0,
        dataKeys: {}
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      goOnDeviceView(filterValue) {
        this.$store.dispatch('SAVE_CONNECTION_WIDGET_DEVICE_FILTER', filterValue.value)
        this.$router.push({ path: ('devices')})
      },
      findDataKeyItem(item) {
        if (item.daysCount < this.dataKeys.min) {
          return this.dataKeys.data.find(el => el.key == this.dataKeys.min)
        }

        if (item.daysCount > this.dataKeys.max) {
          return this.dataKeys.data.find(el => el.key == this.dataKeys.max)
        }

        return this.dataKeys.data.find(el => el.key == item.daysCount)
      },
      initData() {
        this.good = 0
        this.normal = 0
        this.bad = 0
        this.goodPercent = 0
        this.normalPercent = 0
        this.badPercent = 0
        this.dataKeys = {max: 7, min: 0, data: [
            {key: 0, keyAlias: this.$t('widgets.connection.today'), value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 1, keyAlias: this.$t('widgets.connection.yesterday'), value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 2, keyAlias: `2 ${this.$t('widgets.connection.days')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3, keyAlias: `3 ${this.$t('widgets.connection.days')}`, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 4, keyAlias: `4 ${this.$t('widgets.connection.days')}`, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 5, keyAlias: `5 ${this.$t('widgets.connection.days1')}`, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 6, keyAlias: `6 ${this.$t('widgets.connection.days1')}`, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 7, keyAlias: `7 ${this.$t('widgets.connection.days1')}`, value: 0, percent: 0, color: this.getColor('red_color')}
          ]}
      },
      fetchData() {
        this.initData()
        rest.get('/widget/connection', this.getParamsForGroupFilter()).then(response => {
          for (const item of response.data) {
            const insertTo = this.findDataKeyItem(item)
            if (insertTo) {
              insertTo.value += item.metersCount
              if (item.daysCount <= 1) {
                this.good += item.metersCount
              } else if (item.daysCount > 1 && item.daysCount < 7) {
                this.normal += item.metersCount
              } else {
                this.bad += item.metersCount
              }
            }
          }

          for (const item of this.dataKeys.data) {
            item.percent = Math.round(item.value / (this.good + this.normal + this.bad) * 100)
          }

          this.goodPercent = this.good / (this.good + this.normal + this.bad) * 100
          this.normalPercent = this.normal / (this.good + this.normal + this.bad) * 100
          this.badPercent = this.bad / (this.good + this.normal + this.bad) * 100
        })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }

  .data-content {
    padding-top: get-size(20);
  }

</style>
