<template>
  <div class="main" :class="{'clickable ': clickable}" v-on:click="$emit('click')">
    <div class="icon">
      <div class="indicator"  :style="{background: iconColor}"></div>
    </div>
    <div class="description">
      <div class="des-row">
        <div :title="text" class="text">{{text}} </div>
        <div class="value">{{value}}</div>
        <div class="percent">{{percent.toFixed(0).toString()}} %</div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: 'ChartDescriptionItem',
      props: {
        clickable: {
          type: Boolean,
          default: false
        },
        iconColor: {
          type: String,
          default: '#FFFFFF'
        },
        text: {
          type: String,
          default: ''
        },
        value: {
          type: Number,
          default: 0
        },
        percent: {
          type: Number,
          default: 0
        }
      }
    }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../../assets/scss/main";

  .clickable {
    cursor: pointer;
  }
  .main {
    display: inline;
    width: 100%;
  }

  .icon {
    display: inline-block;
    width: 7%;
  }
  .indicator {
    margin-bottom: get-size(4);
    width: get-size(13);
    height: get-size(13);
    border-radius: get-size(8);
  }
  .description {
    display: inline-block;
    width: 93%;
    font-size: get-size(14);
    line-height: get-size(24);
    font-weight: normal;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .text {
    display: inline-block;
    width: 70%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .value{
    display: inline-block;
    width: 15%;
    font-weight: 700;
    text-align: center;
  }
  .percent {
    display: inline-block;
    width: 15%;
  }
  .des-row {
    display: flex;
    align-content: center;
  }

</style>
