export const ERROR_WIDGET_FILTER_VALUES = [
  {id: 0, value: 'NO_ERROR', alias: 'widgets.error.ok', aliasPrefix: '', aliasSuffix: '', needTranslation: true},
  {id: 1, value: 'WITH_ERROR', alias: 'widgets.error.error', aliasPrefix: '', aliasSuffix: '', needTranslation: true},
  {id: 2, value: 'LOST_CONNECTION', alias: 'widgets.error.no_connection', aliasPrefix: '', aliasSuffix: '', needTranslation: true}
]

export const POWER_LEVEL_WIDGET_FILTER_VALUES = [
  {id: 0, value: 'ME-3.6', alias: '>=3.6', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 1, value: 'E-3.5', alias: '3.5', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 2, value: 'E-3.4', alias: '3.4', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 3, value: 'E-3.3', alias: '3.3', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 4, value: 'E-3.2', alias: '3.2', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 5, value: 'E-3.1', alias: '3.1', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 6, value: 'E-3.0', alias: '3.0', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 7, value: 'E-2.9', alias: '2.9', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 8, value: 'LE-2.8', alias: '<=2.8', aliasPrefix: '', aliasSuffix: '', needTranslation: false},
  {id: 9, value: 'M-3', alias: 'widgets.battery_charge.description_good_short', aliasPrefix: '', aliasSuffix: '', needTranslation: true},
  {id: 10, value: 'LE-3', alias: 'widgets.battery_charge.description_bad_short', aliasPrefix: '', aliasSuffix: '', needTranslation: true}
]

export const CONNECTION_WIDGET_FILTER_VALUES = [
  {id: 0, value: 'E-0', alias: 'widgets.connection.today', aliasPrefix: '', aliasSuffix: '', needTranslation: true},
  {id: 1, value: 'E-1', alias: 'widgets.connection.yesterday',  aliasPrefix: '', aliasSuffix: '', needTranslation: true},
  {id: 2, value: 'E-2', alias: 'widgets.connection.days', aliasPrefix: '2 ', aliasSuffix: '', needTranslation: true},
  {id: 3, value: 'E-3', alias: 'widgets.connection.days', aliasPrefix: '3 ', aliasSuffix: '', needTranslation: true},
  {id: 4, value: 'E-4', alias: 'widgets.connection.days', aliasPrefix: '4 ', aliasSuffix: '', needTranslation: true},
  {id: 5, value: 'E-5', alias: 'widgets.connection.days1', aliasPrefix: '5 ', aliasSuffix: '', needTranslation: true},
  {id: 6, value: 'E-6', alias: 'widgets.connection.days1', aliasPrefix: '6 ', aliasSuffix: '', needTranslation: true},
  {id: 7, value: 'N_ME-7', alias: 'widgets.connection.days1', aliasPrefix: '>=7 ', aliasSuffix: '', needTranslation: true},
  {id: 8, value: 'LE-1', alias: 'widgets.connection.description_good', aliasPrefix: '', aliasSuffix: '',  needTranslation: true},
  {id: 9, value: 'M-1-L-7', alias: 'widgets.connection.description_medium_short', aliasPrefix: '', aliasSuffix: '',  needTranslation: true},
  {id: 10, value: 'N_ME-7', alias: 'widgets.connection.description_bad', aliasPrefix: '', aliasSuffix: '', needTranslation: true}
]

