import Vue from 'vue'
import Vuex from 'vuex'
import {APP_TABS} from '../constant'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mode: 'WHITE',
    currentAppTab: APP_TABS.ANALYTICS,
    groupFilter: '',
    deviceFilter: {
      simpleSearch: null,
      errorWidgetFilter: null,
      batteryChargeFilter: null,
      connectionWidgetFilter: null
    }
  },

  getters: {
    MODE: state => {
      return state.mode
    },

    CURRENT_APP_TAB: state => {
      return state.currentAppTab
    },

    GROUP_FILTER: state => {
      return state.groupFilter
    },

    DEVICE_FILTER: state => {
      return state.deviceFilter
    },
    DEVICE_FILTER_IS_PRESENT: state => {
      return state.deviceFilter.simpleSearch ||
        state.deviceFilter.errorWidgetFilter ||
        state.deviceFilter.batteryChargeFilter ||
        state.deviceFilter.connectionWidgetFilter
    }
  },

  mutations: {
    SET_MODE: (state, payload) => {
      state.mode = payload
    },
    SET_CURRENT_APP_TAB: (state, payload) => {
      state.currentAppTab = payload
    },
    SET_GROUP_FILTER: (state, payload) => {
      state.groupFilter = payload
    },
    SET_SIMPLE_SEARCH_DEVICE_FILTER: (state, payload) => {
      state.deviceFilter.simpleSearch = payload
    },
    SET_ERROR_WIDGET_DEVICE_FILTER: (state, payload) => {
      state.deviceFilter.errorWidgetFilter = payload
    },
    SET_BATTERY_CHARGE_WIDGET_DEVICE_FILTER: (state, payload) => {
      state.deviceFilter.batteryChargeFilter = payload
    },
    SET_CONNECTION_WIDGET_DEVICE_FILTER: (state, payload) => {
      state.deviceFilter.connectionWidgetFilter = payload
    },

    CLEAR_DEVICE_FILTER: (state, payload) => {
      state.deviceFilter.errorWidgetFilter = null
      state.deviceFilter.simpleSearch = null
      state.deviceFilter.batteryChargeFilter = null
      state.deviceFilter.connectionWidgetFilter = null
    }
  },

  actions: {
    SAVE_MODE: async (context, payload) => {
      context.commit('SET_MODE', payload)
    },
    SAVE_CURRENT_APP_TAB: async (context, payload) => {
      context.commit('SET_CURRENT_APP_TAB', payload)
    },
    SAVE_GROUP_FILTER: async (context, payload) => {
      context.commit('SET_GROUP_FILTER', payload)
    },
    SAVE_SIMPLE_SEARCH_DEVICE_FILTER: async (context, payload) => {
      context.commit('SET_SIMPLE_SEARCH_DEVICE_FILTER', payload)
    },
    SAVE_ERROR_WIDGET_DEVICE_FILTER: async (context, payload) => {
      context.commit('SET_ERROR_WIDGET_DEVICE_FILTER', payload)
    },
    SAVE_BATTERY_CHARGE_WIDGET_DEVICE_FILTER: async (context, payload) => {
      context.commit('SET_BATTERY_CHARGE_WIDGET_DEVICE_FILTER', payload)
    },
    SAVE_CONNECTION_WIDGET_DEVICE_FILTER: async (context, payload) => {
      context.commit('SET_CONNECTION_WIDGET_DEVICE_FILTER', payload)
    },
    CLEAR_DEVICE_FILTER: async (context, payload) => {
      context.commit('CLEAR_DEVICE_FILTER', payload)
    }
  },

  modules: {}
})
