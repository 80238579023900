<template>
      <div class="map-content">
        <div>
          <gmap-map
            :center="center"
            :zoom="zoom"
            :lang="'en'"
            v-if="feedLoaded"
            ref="map"
            :options="options"
            style="width: 100%; height: 500px;"
          >
            <gmap-marker
              :draggable="true"
              :key="index"
              v-for="(m, index) in locations"
              :position="{
                   lat: parseFloat(m.lat),
                   lng: parseFloat(m.lng)
              }"
            ></gmap-marker>
          </gmap-map>
        </div>
      </div>
</template>

<script>


  export default {
    name: 'GoogleMap',
    props: {
      feedLoaded: {
        type: Boolean,
        default: false
      },
      locations: {
        type: Array,
        default: function() {
          return []
        }
      },
      zoomVal: {
        type: Number,
        default: function() {
          return 12
        }
      },
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        zoom: this.zoomVal,
        center: this.findCenter(),
        options: {
          mapTypeControl: true,
          streetViewControl: true
        },
        mapTypeId: 'styledMapType'
      }
    },
    mounted() {
    },
    methods: {
      findCenter() {
        if (this.locations && this.locations.length  && this.locations.length > 0) {
          if ((this.locations[0].lat > 0) || (parseFloat(this.locations[0].lng) > 0)) {
            return {lat: parseFloat(this.locations[0].lat), lng: parseFloat(this.locations[0].lng)}
          }
        }
        // default center
        return {lat: 50.450135, lng: 30.523559}
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "src/assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }

  .map-content{
    overflow: hidden;
    margin-top: get-size(15);
    width: 100%;
    height: get-size(600);
    margin-bottom: get-size(5);
    padding-right: get-size(5);
  }


</style>
