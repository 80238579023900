<template>
  <div class="main">
  <!--  <div class="icon">
      <div class="indicator" :style="{background: iconColor}"></div>
    </div> -->
    <div v-if="shortTitle" class="description">
      <div class="des-row">
        <div class="text text-short">{{text}} </div>
        <div v-if="strongValue" class="value value-long"><strong>{{value}}</strong></div>
        <div v-else class="value value-long">{{value}}</div>
      </div>
    </div>

    <div v-else class="description">
      <div class="des-row">
        <div class="text">{{text}} </div>
        <div v-if="strongValue" class="value"><strong>{{value}}</strong></div>
        <div v-else class="value">{{value}}</div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: 'DeviceParameterItem',
      props: {
        shortTitle: {
          type: Boolean,
          default: false
        },
        strongValue: {
          type: Boolean,
          default: false
        },
        iconColor: {
          type: String,
          default: '#FFFFFF'
        },
        text: {
          type: String,
          default: ''
        },
        value: {
          type: String,
          default: ''
        }
      }
    }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .main {
    display: inline;
    width: 100%;
  }

  .icon {
    display: inline-block;
    width: 7%;
  }
  .indicator {
    margin-bottom: get-size(4);
    width: get-size(13);
    height: get-size(13);
    border-radius: get-size(8);
  }
  .description {
    display: inline-block;
    width: 93%;
    font-size: get-size(16);
    line-height: get-size(24);
    font-weight: normal;
    text-align: right;
    white-space: pre-wrap;
    overflow: auto;
  }
  .text {
    display: flex;
    width: 70%;
    text-align: left;
    white-space: pre-wrap;
    overflow: auto;
    opacity: 0.65;
    align-items: center;
    justify-content:  flex-start;
  }
  .text-short {
    width: 25%;
  }
  .value{
    display: flex;
    width: 30%;
    font-weight: normal;
    text-align: right;
    align-items: center;
    justify-content:  end;
  }
  .value-long {
    width: 75%;
  }
  .des-row {
    display: flex;
    align-content: center;
  }

</style>
