<template>
  <div :class="{'widget': true}" v-on:click="showDeviceInfo">
    <div class="device_header">
      <div><strong>{{getDeviceType}}</strong></div>
      <div class="info">({{getResourcesType}})</div>
    </div>
    <DeviceParameterItem :text="$t(`device.sigfoxId`)" :value="item.sigfoxId" :strongValue="true"> </DeviceParameterItem>

    <DeviceParameterItem :text="getLastValueLabel" :value="getItemValue"> </DeviceParameterItem>

    <DeviceParameterItem :text="$t(`device.chargeLevel`)" :value="(item.chargeLevel ? item.chargeLevel.toFixed(2) : 0).toString()"> </DeviceParameterItem>

    <DeviceParameterItem :text="$t(`device.lqi`)" :value="getDeviceLqi"> </DeviceParameterItem>

    <DeviceParameterItem :text="$t(`device.lastConnection`)" :value="item.lastConnection ? new Date(item.lastConnection).toLocaleDateString() : ''"> </DeviceParameterItem>

    <DeviceParameterItem :text="$t(`device.personalAccount`)" :value="item.personalAccount" > </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.serialNumber`)" :value="item.serialNumber" > </DeviceParameterItem>

    <DeviceParameterItem v-if="(item.lastErrors && (item.lastErrors.length > 0))" :text="$t(`device.error_count`)" :value="item.lastErrors.length" > </DeviceParameterItem>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import MeterSelectWidget from '../common/MeterSelectWidget'
  import DeviceParameterItem from './DeviceParameterItem'

  export default {
    name: 'Device',
    components: {DeviceParameterItem},
    props: {
      name: {
        type: String,
        default: ''
      },
      item: null
    },
    computed: {
      getItemValue() {
        if (this.item.lastValue) {
          return this.item.lastValue.value.toFixed(2).toString()
        } else {
            return 'no data'
        }
      },
      getDeviceLqi() {
        if (this.item.lqi) {
          return this.$t(`device.${this.item.lqi}`)
        } else {
          return 'no data'
        }
      },
      getLastValueLabel() {
        if (this.item.lastValue) {
          return (this.$t('device.lastValue') + ' (' + this.$t(`device.${this.item.unit}`) + ')' + ' ' + new Date(this.item.lastValue.date).toLocaleDateString())
        } else {
          return 'no data'
        }
      },
      getResourcesType() {
        return this.$t(`device.${this.item.resourcesType}`)
      },
      getDeviceType() {
        return this.$t(`device.${this.item.iotDeviceType}`)
      }
    },
    data() {
      return {
        loaded: false
      }
    },
    mounted() {
    },
    methods: {
      showDeviceInfo() {
        const path = 'devices/' + this.item.id
        this.$router.push({ path: path})
      },
      selectMeters(meters) {
        this.feedLoaded = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .widget {
    width: 24%;
    min-width: 24%;
    margin-right: get-size(0);
    margin-right: get-size(5);
    margin-bottom: get-size(5);

    display: block;
    padding-top: get-size(10);
    padding-bottom: get-size(10);
    cursor: pointer;
  }


  .error {
    border: get-size(5) solid #ff101b !important;

  }
  .device_header {
    width: 100%;
    text-align: center;
    margin-bottom: get-size(10);
    strong {
      font-weight: 500;
    }
  }
  .info {
    opacity: 0.65;
  }


  @media (max-width: 850px) {
    .widget {
      width: 32%;
      min-width: 32%;
    }
  }

  @media (max-width: 640px) {
    .widget {
      width: 49%;
      min-width: 49%;
    }
  }

  @media (max-width: 400px) {
    .widget {
      width: 96%;
      min-width: 96%;
    }
  }
</style>
