<template>
    <div class="report">
      <div class="filter-container">
        <h2>{{$t(`report.report-title`)}}</h2>

        <div class="group-container">
          <group-select-widget v-show="showGroupFilter"
                               @hideGroupFilter="showGroupFilter=false"
                               @showGroupFilter="showGroupFilter=true"
                               @select="selectObjects"></group-select-widget>
        </div>

        <div class="date-container">
          <span>{{$t(`report.date-label`)}}</span>
          <date-picker v-if="activeLocale() === 'en'" :default-value="new Date()" :lang=en v-model="reportDate" type="date" ></date-picker>

          <date-picker v-else :lang=uk :default-value="new Date()" v-model="reportDate" type="date" ></date-picker>
        </div>

        <div class="date-container">
          <b-button class="action-btn"  @click="createReport">{{$t(`report.create-text`)}}</b-button>
          <b-button v-if="jsondata && jsondata.length > 0" class="action-btn" @click="exportAsExcel">
            {{$t(`report.export-text`)}}
          </b-button>
        </div>
      </div>

      <div class="table-container"  v-if="jsondata && jsondata.length > 0">
        <report-table :sortQuery="sortQuery" @setSort="sort => handleSort(sort)" :fields="reportFields" :data="jsondata"></report-table>
      </div>
      <div v-else><span class="no-data" v-if="reportFields && reportFields.length > 0">{{$t(`report.no-report-data`)}}</span></div>

      <div class="table-container-export"  v-if="jsondata && jsondata.length > 0">
        <vue-excel-editor
          :no-header-edit="true"
          :no-footer="true" :disable-panel-filter="true"
          :readonly="true" :disable-panel-setting="true"
          ref="grid" v-model="jsondata">
            <vue-excel-column width="150px" v-for="item in reportFields" :key="item.field" :field = "item.field" :label = "item.label"  :type = "item.type" />
        </vue-excel-editor>
      </div>
    </div>
</template>

<script>
  import {BButton} from 'bootstrap-vue'
  import DefaultContainerScroll from '../DefaultContainerScroll'
  import {ACCESS_TOKEN, APP_TABS} from '../../constant'
  import router from '../../router'
  import GroupSelectWidget from '../common/GroupSelectWidget'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  import uk from 'vue2-datepicker/locale/uk'
  import en from 'vue2-datepicker/locale/en'
  import {rest} from '../../api'
  import ReportTable from './ReportTable'

  export default {
    components: {ReportTable, DatePicker, GroupSelectWidget, BButton, DefaultContainerScroll},
    name: 'ReportOnDate',
    mounted() {
      this.reportDate = new Date()
      this.name = this.$currentUser ? this.$currentUser.email : ''
    },
    created() {
      window.addEventListener('resize', this.resizeHandler)
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    data() {
      return {
        reportFields: [],
        en,
        uk,
        showGroupFilter: true,
        jsondata: [],
        name: '',
        waterWidgetKey: 1,
        setting: false,
        widgetsKey: 1,
        reportDate: null,
        groupFilter: null,
        sortQuery: {},
        page: 0,
        count: 200
      }
    },
    methods: {
      handleSort(sort) {
        this.sortQuery = sort
        this.createReport()
        /*
        if (this.jsondata && this.jsondata.length) {
          this.jsondata = this.jsondata.sort((a, b) => {
            const nameA = a[sort.field]
            const nameB = b[sort.field]
            if (nameA < nameB) {
              if (sort.ascDir) {
                return -1
              }
              return 1
            }
            if (nameA > nameB) {
              if (sort.ascDir) {
                return 1
              }
              return -1
            }

            return 0
          })


        }*/
      },
      activeLocale() {
        return this.$i18n.locale
      },
      selectObjects(objects) {
        this.groupFilter = objects
      },
      createReport() {
        this.reportFields = [
          {autoFillWidth: true, field: 'sigFoxId', label: this.$t('report.sigFoxId-title'), type: 'string'},
          {autoFillWidth: true, field: 'date', label: this.$t('report.date-title'), type: 'date'},
          {autoFillWidth: true, field: 'value', label: this.$t('report.value-title'), type: 'number'},
          {autoFillWidth: true, field: 'serialNumber', label: this.$t('report.serialNumber-title'), type: 'string'},
          {autoFillWidth: true, field: 'status', label: this.$t('report.status-title'), type: 'string'},
          {autoFillWidth: true, field: 'address', label: this.$t('report.address-title'), type: 'string'},
          {autoFillWidth: true, field: 'installationPlace', label: this.$t('report.installationPlace-title'), type: 'string'},
          {autoFillWidth: true, field: 'personalAccount', label: this.$t('report.personalAccount-title'), type: 'string'}
        ]
        this.jsondata = []

        rest.get('/report/total', this.getParamsForReport()).then(response => {
          this.jsondata = response.data
          if (this.jsondata) {
            this.jsondata.forEach(item => {
              if (item.date) {
                item.date = new Date(item.date).toLocaleDateString()
              }
              item.status = this.$t('device.' + item.status)
            })
          }
        })
          .catch(error => {
            console.log(error)
          })
      },
      exportAsExcel() {
        const format = 'xlsx'
        const exportSelectedOnly = false
        const filename = ('report_on_date_' + new Date())
        this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
      },
      resizeHandler(e) {
        this.waterWidgetKey++
      },
      getParamsForReport() {
        let dateParam = null
        let groupParam = null
        if (this.reportDate) {
          const getYear = this.reportDate.toLocaleString('default', { year: 'numeric'})
          const getMonth = this.reportDate.toLocaleString('default', { month: '2-digit'})
          const getDay = this.reportDate.toLocaleString('default', { day: '2-digit'})
          dateParam = getYear + '-' + getMonth + '-' + getDay
        }
        if (this.groupFilter) {
          groupParam = this.groupFilter.map(item  => item.id).join()
        }

        let paramsObj = null

        if (groupParam || dateParam) {
          paramsObj = {
            params: {}
          }
          if (groupParam) {
            paramsObj.params.groupFilter = groupParam
          }
          if (dateParam) {
            paramsObj.params.date = dateParam
          }
        }
        if (this.sortQuery) {
          paramsObj.params.sortField = this.sortQuery.field
          paramsObj.params.ascDirection = this.sortQuery.ascDir
        }

        paramsObj.params.page = this.page
        paramsObj.params.count = this.count
        return paramsObj
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "src/assets/scss/main";

  h2 {
    font-size: get-size(24);
    font-weight: normal;
  }

  .report {
    height: auto;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding: get-size(10) get-size(10) get-size(10) get-size(10);
  }

  .action-btn {
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: get-size(5);
    margin-right: get-size(5);
    font-size: get-size(18);
    //  height: get-size(30);
    padding: get-size(5) get-size(10) get-size(5) get-size(10);
  }

  .filter-container {
    margin: get-size(0) get-size(0) get-size(20) get-size(0);
    padding-right: get-size(20);
    padding-left: get-size(20);
    border: get-size(1) solid #fff;
    border-radius: get-size(5);
  }

  .date-container {
    width: 50%;
    display: flex;
    align-items: center;
    padding-bottom: get-size(10);

    span {
      text-align: left;
      width: get-size(110);
      margin-right: get-size(10);
    }
  }

  .group-container {
    width: get-size(460);
    padding-bottom: get-size(10);
  }

  .mx-datepicker {
    width: get-size(340);
  }

  .mx-input {
    width: 100% !important;
  }

  .table-container {
    width: 100%;
    height: auto;
    overflow-x: auto;
  }

  /deep/ .component-content {
    border: none !important
  }

  .table-container-export {
    width: 0%;
    height: 0%;
    overflow: hidden;
    position: absolute;
  }

  .no-data {
    font-weight: bold;
    font-size: get-size(20);
  }

  @media (max-width: 640px) {
    .mx-datepicker {
      width: 70%;
    }
    .group-container {
      width: 85%;
      padding-bottom: get-size(10);
    }
    .date-container {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    .mx-datepicker {
      width: 70%;
    }
    .group-container {
      width: 100%;
    }
    .date-container {
      width: 100%;
    }
  }
</style>
