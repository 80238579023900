<template>
  <div v-if="data && data.length > 0" class="main">
      <div :class="{'row': true,'header-row': header}">
        <template v-if="header">
            <div  v-on:click="setSort(item.field)" :class="{'table-field': true,
            'header-field': true,
            'address-field': (item.field === 'address' || item.field === 'serialNumber' || item.field === 'installationPlace' || item.field === 'personalAccount')}"
                  v-for="(item, index) in data"   :key="index">
              <span >{{item.value}}</span>
              <span v-if="sort && sort.field == item.field">
                <svg v-if="sort.ascDir" data-v-6242c37e="" width="0.5rem" height="0.8rem" viewBox="0 0 20 30" fill="#999" xmlns="http://www.w3.org/2000/svg" style="margin-left: 2px;"><path data-v-6242c37e="" d="M10 0L10 20" stroke="#999" stroke-width="1.5"></path><path data-v-6242c37e="" d="M19 11L10 20L1 11" stroke="#666" stroke-width="1.5"></path></svg>
                <svg v-else data-v-6242c37e="" width="0.5rem" height="0.8rem" viewBox="0 0 20 30" fill="#999" xmlns="http://www.w3.org/2000/svg" style="margin-left: 2px;"><path data-v-6242c37e="" d="M10 22L10 2" stroke="#999" stroke-width="1.5"></path><path data-v-6242c37e="" d="M19 11L10 1.99999L1 11" stroke="#1A1A1A" stroke-width="1.5"></path></svg>
              </span>
            </div>
        </template>
        <template v-else>
          <div  :class="{'table-field': true,
          'number-field': item.type === 'number',
          'date-field': item.type === 'date',
          'string-field': item.type === 'string',
          'address-field': (item.field === 'address' || item.field === 'serialNumber' || item.field === 'installationPlace' || item.field === 'personalAccount')
          }"  v-for="(item, index) in data"   :key="index">{{item.value}} </div>
        </template>
      </div>
    </div>
</template>

<script>
    export default {
      name: 'ReportItem',
      props: {
        sortQuery: {},
        header: {
          type: Boolean,
          default: false
        },
        shortTitle: {
          type: Boolean,
          default: false
        },
        strongValue: {
          type: Boolean,
          default: false
        },
        iconColor: {
          type: String,
          default: '#FFFFFF'
        },
        text: {
          type: String,
          default: ''
        },
        value: {
          type: String,
          default: ''
        },
        data: null
      },
      data() {
        return {
          sort: {
            field: null,
            ascDir: true
          }
        }
      },
      mounted() {
        this.sort = this.sortQuery
      },
      methods: {
        setSort(field) {
          if (this.sort && this.sort.field === field) {
            this.sort.ascDir = !this.sort.ascDir
          } else {
            this.sort.field = field
            this.sort.ascDir = true
          }
          this.$emit('setSort', this.sort)
        }
      }
    }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .main {
    display: flex;
    width: auto;
  }
  .table-field {
    display: flex;
    width: get-size(150);
    min-width: get-size(150);
    text-align: left;
    align-items: center;
    justify-content:  start;
    padding:get-size(2) get-size(5) get-size(2) get-size(5);
    opacity: 0.7;
  }
  .address-field {
    width: get-size(300);
    min-width: get-size(300);
  }
  .header-field {
    cursor: pointer;
    opacity: 1;
    justify-content: center;
    padding:get-size(5) get-size(5) get-size(5) get-size(5);
    text-align: center;
  }
  .number-field {
    justify-content: end;
  }
  .date-field {
    justify-content: center;
  }
  .string-field {
    justify-content: start;
  }
  .row {
    width: auto;
    display: flex;
    align-content: center;
  }

</style>
