import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {ACCESS_TOKEN} from './constant'
import {GOOGLE_MAP_API_KEY} from '../local/constant.local'
import {rest, initCurrentUser} from './api'
import * as VueGoogleMaps from 'vue2-google-maps'
import {i18n} from '@/plugins/i18n'


Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: `${GOOGLE_MAP_API_KEY}`,
    language: process.env.VUE_APP_I18N_LOCALE || 'en'
  }
})

if (!localStorage.getItem(ACCESS_TOKEN)) {
  initVueApp()
  router.push('login').catch(() => {})
} else {
  initCurrentUser(initUserSuccess, initUserFailure)
}


export function initVueApp() {
  new Vue({
    router: router,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
}

function initUserSuccess() {
  initVueApp()
  let pathStr = router.currentRoute.fullPath
  if (pathStr.includes('login')) {
    pathStr = '/'
  }
  router.push(pathStr).catch(() => {})
}

function initUserFailure() {
  initVueApp()
  router.push('login').catch(() => {})
}

