<template>
  <DefaultContainerScroll>
    <div class="setting">
      <div class="menu">
        <div class="title_1">
          <h2>{{$t(`view.widgets.setting`)}}</h2>
        </div>
        <div class="setting_container">
          <div class="setting_item_group">
            <div class="setting-title">
              {{$t(`view.widgets.language_label`)}}
            </div>
            <div class="radio-container">
              <div :class="{'setting-item-first': true, 'border_selected': (currentLocale == 'ua')}"
                   v-on:click="setLocale('ua')">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                       :checked="currentLocale == 'ua'">
                <label class="form-check-label" for="inlineRadio1">{{$t(`view.widgets.ua`)}}</label>
              </div>
              <div :class="{'setting-item': true, 'border_selected': (currentLocale == 'en')}"
                   v-on:click="setLocale('en')">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                       :checked="currentLocale == 'en'">
                <label class="form-check-label" for="inlineRadio2">{{$t(`view.widgets.en`)}}</label>
              </div>
            </div>
          </div>

          <div class="setting_item_group">
            <div class="setting-title">
              {{$t(`view.widgets.theme_label`)}}
            </div>

            <div class="radio-container">
              <div :class="{'setting-item-first': true, 'border_selected': (currentMode == 'WHITE')}"
                   v-on:click="setMode('WHITE')">
                <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio4"
                       :checked="currentMode == 'WHITE'">
                <label class="form-check-label" for="inlineRadio4">{{$t(`view.widgets.white`)}}</label>
              </div>
              <div :class="{'setting-item': true, 'border_selected': (currentMode == 'DARK')}"
                   v-on:click="setMode('DARK')">
                <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio5"
                       :checked="currentMode == 'DARK'">
                <label class="form-check-label" for="inlineRadio5">{{$t(`view.widgets.dark`)}}</label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </DefaultContainerScroll>
</template>

<script>
  import {rest} from '../../api'
  import {common} from '../../mixin/mixin'
  import DefaultContainerScroll from '../DefaultContainerScroll'
  import {BButton} from 'bootstrap-vue'
  import {APP_TABS} from '../../constant'

  export default {
    name: 'SettingComponent',
    components: {DefaultContainerScroll},
    props: {},
    computed: {
      currentMode() {
        return this.$store.getters.MODE
      }
    },
    data() {
      return {
        currentLocale: 'en'
      }
    },
    mounted() {
      this.currentLocale = this.$currentUser.locale
      this.$store.dispatch('SAVE_CURRENT_APP_TAB', APP_TABS.SETTINGS)
    },
    methods: {
      setLocale(locale) {
        rest.patch('/user/setting', {locale: locale}).then(response => {
          this.currentLocale = locale
          this.$i18n.locale = this.currentLocale
          this.$currentUser.locale = this.currentLocale
          this.$emit('settingChanged')
        }).catch(error => {
          console.log(error)
        })
      },
      setMode(mode) {
        rest.patch('/user/setting', {mode: mode}).then(response => {
          this.$currentUser.mode = this.currentMode
          this.$store.dispatch('SAVE_MODE', mode)
          this.$emit('settingChanged')
        }).catch(error => {
          console.log(error)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: get-size(18);
    height: get-size(18);
    padding: 0;
    /* background-color only for content */
    background-clip: content-box;
    border: none;
    // background-color: #ccc;
    border-radius: 50%;
    margin: 0;
    margin-right: get-size(10);
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #8024FF;
  }

  .setting {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100%;

    .menu {
      font-size: get-size(18);
      position: relative;
      width: get-size(500);
      min-width: get-size(500);
      height: get-size(360);
      min-height: get-size(350);

      border-radius: get-size(10);
      margin-top: get-size(100);

      .setting_item_group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: get-size(30);

        .radio-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        .setting-title {
         // padding-left: get-size(45);
          font-size: get-size(22);
          width: 100%;
          margin-bottom: get-size(15);
        }

        .setting-item {
          width: 35%;
          display: flex;
          border: solid get-size(1) #999999;
          border-radius: get-size(18);
          padding: get-size(15);
          cursor: pointer;
        }

        .setting-item-first {
          width: 35%;
          display: flex;
          border: solid get-size(1) #999999;
          border-radius: get-size(18);
          padding: get-size(15);
          margin-right: get-size(10);
          cursor: pointer;
        }
      }

      .border_selected {
        border: solid get-size(2) #8024FF !important;
        background-color: rgba(128, 36, 255, 0.1);
      }

      .setting_container {
        width: 100%;
        height: 100%;
      }

      .title_1 {
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        padding-top: get-size(15);
        padding-bottom: get-size(15);

        h1 {
          font-size: get-size(35);
          font-style: normal;
          font-weight: 500;
          line-height: get-size(26);
          text-align: center;
          margin: 0;
          margin-top: get-size(13);
          margin-bottom: 0;
        }

        h2 {
          font-size: get-size(25);
          font-style: normal;
          font-weight: 500;
          line-height: get-size(24);
          text-align: center;
          margin: 0;
          margin-bottom: get-size(10);
        }

        h3 {
          font-size: get-size(14);
          font-style: normal;
          font-weight: 400;
          line-height: get-size(24);
          text-align: center;
          margin: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    right: get-size(5);
    top: get-size(13);
    font-size: get-size(20);
    cursor: pointer;
  }

  .form-check-input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  .form-check-label {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @media (max-width: 640px) {
    .setting .menu {
      width: get-size(400);
      min-width: get-size(400);
      height: get-size(320);
      min-height: get-size(320);
    }
  }

  @media (max-width: 400px) {
    .setting .menu {
      width: get-size(400);
      min-width: get-size(400);
      height: get-size(320);
      min-height: get-size(320);
    }
  }
</style>
