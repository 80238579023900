export const ACCESS_TOKEN = 'access_token'

export const WHITE_MODE = {
    red_color: '#E89AA9',
    green_color: '#00BDB5',
    blue_color: '#5E709E',
    yellow_color: '#EBB017',
    axis_color: '#B3B3B3',
    scale_color: '#B3B3B3',
    graph_color: '#8024FF',
    graph_background_color: '#8024FF'
}

export const DARK_MODE = {
    red_color: '#B82F50',
    green_color: '#24B670',
    blue_color: '#5577E6',
    yellow_color: '#EBB017',
    axis_color: '#48467C',
    scale_color: '#9A96BE',
    graph_color: '#48467C',
    graph_background_color: '#9A96BE'
}

export const MODE_MAPPING = {
    WHITE: WHITE_MODE,
    DARK: DARK_MODE
}

export const APP_TABS = {
  ANALYTICS: 'analytics',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  DEVICES: 'devices',
  DEVICE_INFO: 'device_info'
}
