<template>
  <DefaultContainerScroll>
    <div class="login_content">
      <div class="login">
        <div class="logo">
          <b-img blank></b-img>
        </div>
        <div class="title">
          <span>{{$t(`view.login.systemName`)}}</span>
        </div>
        <div class="login-input">
          <div>
            <label>{{$t(`view.login.login`)}}</label>
            <b-form-input  v-model="email" :placeholder="$t(`view.login.login`)"></b-form-input>
          </div>
          <div>
            <label>{{$t(`view.login.pass`)}}</label>
            <b-form-input   :id="`password`" :type="'password'" v-model="password" :placeholder="$t(`view.login.pass`)"></b-form-input>
          </div>
        </div>
        <div class="login-controls">
          <b-button v-on:click="login">{{$t(`view.login.input`)}}</b-button>
          <a>{{$t(`view.login.forgot_pass_text`)}}</a>
        </div>
      </div>
    </div>
  </DefaultContainerScroll>
</template>

<script>

    import {rest, initCurrentUser} from '../api'
    import DefaultContainerScroll from '../component/DefaultContainerScroll'
    import {BFormInput} from 'bootstrap-vue'
    import {ACCESS_TOKEN} from '../constant'

    export default {
        name: 'Login',
        components: {BFormInput, DefaultContainerScroll},
        data() {
            return {
                email: '',
                password: ''
            }
        },
        methods: {
            login() {
                var bodyFormData = new FormData()
                bodyFormData.append('email', this.email)
                bodyFormData.append('password', this.password)
                rest.post('/login', bodyFormData).then(response => {
                  const token = response.data.replaceAll('access_token: ', '').trim()
                  localStorage.setItem(ACCESS_TOKEN, token)
                  this.$router.go()
                })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }
</script>

<style lang="scss">
  /* Custom Styles */
  @import "../assets/scss/main";


  .login-input {
    display: flex;
    flex-direction: column;

    div {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    input {
      background: #F9F8F8;
      /* Graph-text-02 */
      border: get-size(1) solid #B3B3B3;
      border-radius: get-size(8);
      height: get-size(46);
      border-radius: get-size(8);
      margin-bottom: get-size(20);

      font-size: get-size(18);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(20);
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 0.87);
    }

    label {
      position:absolute;
      top:get-size(-7);
      left:get-size(10);
      background-color:#FFFFFF;
      text-align: center;

      font-size: get-size(12);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(16);
      letter-spacing: 0px;
      padding-left: get-size(2);
      padding-right: get-size(2);
      border-radius: get-size(3);
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .login_content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: get-size(18);
  }

  .login {
    display: flex;
    flex-direction: column;
    width: get-size(400);
    height: get-size(496);
    background-color: #FFFFFF;
    margin: auto;
    margin-top: auto;
    box-shadow: 0px 0px get-size(15) 0px #00000040;
    // border-style: groove;
   // border-bottom-width: get-size(2);
    padding: get-size(40);
    border-radius: get-size(10);
  }

  .login-header {
    display: flex;
    align-items: center;
    background-color: #5600E8;
    width: 100%;
    height: get-size(45);
    color: #fff;
    font-size: get-size(26);
    margin-bottom: get-size(10);
  }

  .name {
    color: #6200EE;
    font-size: get-size(14);
    font-weight: bold;
    margin-left: get-size(50);
  }

  .login-header div {
    margin:auto;
  }

  .login-controls {
    display: flex;
    flex-direction: column;
    margin-top: get-size(20);
    align-items: center;
  }

  .login-controls button {
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: get-size(22);
    line-height: get-size(24);
    background-color: #8024FF;
    width: 100%;
    border: none;

    height: get-size(57);
    border-radius: get-size(8);
    margin-bottom: get-size(25);
  }

  .login-controls a {
    font-style: normal;
    font-weight: normal;
    font-size: get-size(14);
    line-height:  get-size(16);
    color: #8024FF;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      display: block;
      max-width:get-size(200);
      max-height:get-size(103);
      min-width:get-size(200);
      min-height:get-size(103);
      width: auto;
      height: auto;
      margin: 0;
    }
  }

  .title {
    font-size: get-size(22);
    font-style: normal;
    font-weight: 400;
    line-height: get-size(24);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: get-size(30);
  }

</style>
