<template>
  <div class="current-widget">
    <div class='main_header'>
      <div class="big">
        <span>{{$t(`widgets.checking.title_big`)}}</span>
      </div>
      <div class="small">
        <span>{{$t(`widgets.checking.title_small`)}}</span>
      </div>
    </div>
    <div class="chart-content">
      <template v-for="(item, index) in dataKeys.data">
        <VerticalLineChartItem
          :keyWidh="'18%'"
          :indicatorBgWidh="'58%'"
          :key="index"
          :keyVal="item.keyAlias"
          :value="item.value"
          :percent="item.percent"
          :indicatorColor="item.color">
        </VerticalLineChartItem>
      </template>
    </div>
    <div class="data-content">
      <ChartDescriptionItem
        :iconColor="getColor('green_color')"
        :text="$t(`widgets.checking.description_good`)"
        :value="good"
        :percent="good/total * 100"
      ></ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('blue_color')"
        :text="$t(`widgets.checking.description_medium`)"
        :value="normal"
        :percent="normal/total * 100"
      ></ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('red_color')"
        :text="$t(`widgets.checking.description_bad`)"
        :value="bad"
        :percent="bad/total * 100"
      ></ChartDescriptionItem>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import VerticalLineChartItem from '../widget/util/VerticalLineChartItem'
  import ChartDescriptionItem from '../widget/util/ChartDescriptionItem'
  import {common} from '../../mixin/mixin.js'

  export default {
    name: 'CheckingWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, VerticalLineChartItem, ChartDescriptionItem},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        good: 0,
        normal: 0,
        bad: 0,
        total: 0,
        dataKeys: {}
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      initData() {
        this.good = 0
        this.normal = 0
        this.bad = 0
        this.total = 0
        this.dataKeys = {max: 48, min: 0, data: [
            {key: 48, keyAlias: `4 ${this.$t('widgets.checking.years')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 42, keyAlias: `3.5 ${this.$t('widgets.checking.years')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 36, keyAlias: `3 ${this.$t('widgets.checking.years')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 30, keyAlias: `2.5 ${this.$t('widgets.checking.years')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 24, keyAlias: `2 ${this.$t('widgets.checking.years')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 18, keyAlias: `1.5 ${this.$t('widgets.checking.years')}`, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 12, keyAlias: `1 ${this.$t('widgets.checking.year')}`, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 6, keyAlias: `0.5 ${this.$t('widgets.checking.year1')}`, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 0, keyAlias: '0', value: 0, percent: 0, color: this.getColor('red_color')}
          ]}
      },
      fetchData() {
        this.initData()
        rest.get('/widget/check', this.getParamsForGroupFilter()).then(response => {
          const arr = response.data


          for (const val of arr) {
            this.fillDataValue(val)
          }

          for (const item of this.dataKeys.data) {
            item.percent = Math.round(item.value / this.total * 100)
          }
        })
          .catch(error => {
            console.log(error)
        })
      },

      fillDataValue(value) {
        this.total += value.meterCount
        if (value.weekCount >= 42) {
          this.good += value.meterCount
          this.dataKeys.data[0].value += value.meterCount
        } else if (value.weekCount <= 42 && value.weekCount > 36) {
          this.good += value.meterCount
          this.dataKeys.data[1].value += value.meterCount
        } else if (value.weekCount <= 36 && value.weekCount > 30) {
          this.good += value.meterCount
          this.dataKeys.data[2].value += value.meterCount
        } else if (value.weekCount <= 30 && value.weekCount > 24) {
          this.good += value.meterCount
          this.dataKeys.data[3].value += value.meterCount
        } else if (value.weekCount <= 24 && value.weekCount > 18) {
          this.good += value.meterCount
          this.dataKeys.data[4].value += value.meterCount
        } else if (value.weekCount <= 18 && value.weekCount > 12) {
          this.good += value.meterCount
          this.dataKeys.data[5].value += value.meterCount
        } else if (value.weekCount <= 12 && value.weekCount > 6) {
          this.normal += value.meterCount
          this.dataKeys.data[6].value += value.meterCount
        } else if (value.weekCount <= 6 && value.weekCount > 0) {
          this.normal += value.meterCount
          this.dataKeys.data[7].value += value.meterCount
        } else if (value.weekCount <= 0) {
          this.bad += value.meterCount
          this.dataKeys.data[8].value += value.meterCount
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }

  .data-content {
    padding-top: get-size(20);
  }


</style>
