<template>
    <div class="main" :class="{'clickable ': clickable}" @click="$emit('click')">
      <div class="key" :style="{width: keyWidh}">
        {{keyVal}}
      </div>
      <div class="indicator-bg" :style="{width: indicatorBgWidh}">
        <div class="indicator" :style="{width: indicatorWidh, background: indicatorColor}">
        </div>
      </div>
      <div class="value" :style="{width: valueWidh}">
        {{value}}
      </div>
      <div class="percent" :style="{width: percentWidh}">
        {{percent.toFixed(0).toString()}}%
      </div>
    </div>
</template>

<script>
    export default {
        name: 'VerticalLineChartItem',
        props: {
          clickable: {
            type: Boolean,
            default: false
          },
          keyWidh: {
            type: String,
            default: '8%'
          },
          indicatorBgWidh: {
            type: String,
            default: '68%'
          },
          indicatorColor: {
            type: String,
            default: '#ffff'
          },
          valueWidh: {
            type: String,
            default: '12%'
          },
          percentWidh: {
            type: String,
            default: '12%'
          },
          keyVal: {
            type: String,
            default: '0.0'
          },
          value: {
            type: Number,
            default: 0.0
          },
          percent: {
            type: Number,
            default: 0
          }
        },
        computed: {
          indicatorWidh() {
            return (this.percent + '%')
          }
        }
    }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../../assets/scss/main";
  .clickable {
    cursor: pointer;
  }
  .main {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: get-size(14);
    line-height: get-size(28);
  }

  .key {
      display: inline-block;
      text-align: left;
  }
  .indicator-bg {
    display: inline-block;
    border-radius: get-size(5);
    height: get-size(10);
  }
  .indicator {
    border-radius: get-size(5);
    height: 100%;
  }
  .value{
    display: inline-block;
    text-align: right;
  }
  .percent {
    display: inline-block;
    text-align: right;
  }



</style>
