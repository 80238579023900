<template>
  <div class="widget" v-if="item">
   <div class="device_header">
      <div><strong>{{getDeviceType}}</strong></div>
      <div class="info">({{getResourcesType}})</div>
    </div>
    <DeviceParameterItem :text="$t(`device.sigfoxId`)" :value="item.sigfoxId" :strongValue="true"> </DeviceParameterItem>
    <DeviceParameterItem :text="getLastValueLabel" :value="getItemValue"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.chargeLevel`)" :value="(item.chargeLevel ? item.chargeLevel.toFixed(2).toString() : '')"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.lqi`)" :value="getDeviceLqi"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.lastConnection`)" :value="new Date(item.lastConnection).toLocaleDateString()"> </DeviceParameterItem>
    <hr>
    <DeviceParameterItem :text="$t(`device.status`)" :value="getStatus"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.personalAccount`)" :value="item.personalAccount" > </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.serialNumber`)" :value="item.serialNumber" > </DeviceParameterItem>
    <DeviceParameterItem :shortTitle="true" :text="$t(`device.address`)" :value="item.address"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.installationPlace`)" :value="item.installationPlace" > </DeviceParameterItem>
    <hr>
    <DeviceParameterItem  :text="$t(`device.sigfoxDeviceTypeName`)" :value="item.sigfoxDeviceType? item.sigfoxDeviceType.typeName : ''"> </DeviceParameterItem>
    <DeviceParameterItem  :text="$t(`device.sigfoxDeviceTypeId`)" :value="item.sigfoxDeviceType? item.sigfoxDeviceType.typeId : ''"> </DeviceParameterItem>
    <hr>
    <DeviceParameterItem :shortTitle="true" :text="$t(`device.contractId`)" :value="item.sigfoxDeviceType ? item.sigfoxDeviceType.contract.sigfoxId :''"> </DeviceParameterItem>
    <DeviceParameterItem :shortTitle="true" :text="$t(`device.contractName`)" :value="item.sigfoxDeviceType ? item.sigfoxDeviceType.contract.name : ''"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.contractStartDate`)" :value="item.sigfoxDeviceType ? new Date(item.sigfoxDeviceType.contract.startDate).toLocaleDateString() : ''"> </DeviceParameterItem>
    <DeviceParameterItem :text="$t(`device.contractEndDate`)" :value="item.sigfoxDeviceType ? new Date(item.sigfoxDeviceType.contract.endDate).toLocaleDateString() : ''"> </DeviceParameterItem>
    <div  class="device_header">
      <hr>
      <div >{{$t(`device.errors`)}}</div>
      <div v-if="item.lastErrors && item.lastErrors.length > 0">
        <DeviceParameterItem v-for="err in item.lastErrors" :text="new Date(err.date).toLocaleDateString()" :value="err.errorType"> </DeviceParameterItem>
      </div>
      <DeviceParameterItem v-else :text="$t(`device.noErrors`)" :value="''"> </DeviceParameterItem>
    </div>
    <div class="map-container">
      <google-map :locations="location" :feedLoaded="true"></google-map>
    </div>
    <div class="footer-container"  >

    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import GoogleMap from '../widget/util/GoogleMap'
  import DeviceParameterItem from './DeviceParameterItem'
  import {APP_TABS} from '../../constant'

  export default {
    name: 'DeviceInfo',
    components: {DeviceParameterItem, GoogleMap},
    props: {
      id: null,
      name: {
        type: String,
        default: ''
      }
    },
    computed: {
      getStatus() {
        return this.$t(`device.${this.item.status}`)
      },
      getItemValue() {
        if (this.item.lastValue) {
          return this.item.lastValue.value.toFixed(2).toString()
        } else {
            return 'no data'
        }
      },
      getDeviceLqi() {
        if (this.item.lqi) {
          return this.$t(`device.${this.item.lqi}`)
        } else {
          return 'no data'
        }
      },
      getLastValueLabel() {
        if (this.item.lastValue) {
          return (this.$t('device.lastValue') + ' (' + this.$t(`device.${this.item.unit}`) + ')' + ' ' + new Date(this.item.lastValue.date).toLocaleDateString())
        } else {
          return 'no data'
        }
      },
      getResourcesType() {
        return this.$t(`device.${this.item.resourcesType}`)
      },
      getDeviceType() {
        return this.$t(`device.${this.item.iotDeviceType}`)
      }
    },
    created() {
      window.addEventListener('resize', this.resizeHandler)
      this.$store.dispatch('SAVE_CURRENT_APP_TAB', APP_TABS.DEVICE_INFO)
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    data() {
      return {
        location: [],
        loaded: false,
        item: null
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        this.loading = true
        rest.get('/devices/' + this.id).then(response => {
          this.item = response.data
          this.loading = false
          this.location.push(this.item)
        })
          .catch(error => {
            console.log(error)
            this.loading = false
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .map-container {
    display: flex;
    align-self: center;
    width: 100%;
  }
  .widget {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-top: get-size(10);
    padding-bottom: get-size(10);
    margin: auto;
  }
  .device_header {
    width: 100%;
    text-align: center;
    margin-bottom: get-size(10);
    strong {
      font-weight: 500;
    }
  }
  .info {
    opacity: 0.65;
  }

  hr {
    width: 100%;
  }

  @media (max-width: 850px) {
    .widget {
      width: 70%;
      min-width: 70%;
    }
  }

  @media (max-width: 640px) {
    .widget {
      width: 90%;
      min-width: 90%;
    }
  }

  @media (max-width: 400px) {
    .widget {
      width: 94%;
      min-width: 94%;
    }
  }
</style>
