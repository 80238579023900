<template>
  <div class="main">
      <multiselect
        v-model="selectedValues"
        :deselect-label="$t(`widgets.select.delete`)"
        :selectLabel="$t(`widgets.select.selectItem`)"
        :selectedLabel="$t(`widgets.select.selectedLabel`)"
        track-by="id"
        :options="options"
        :searchable="false"
        :multiple="true"
        :loading="isLoading"
        :internal-search="false"
        :allow-empty="true"
        :close-on-select="false"
        :clear-on-select="false"
        :options-limit="10"
        :limitText="limitText"

        :limit="3"
        :placeholder="$t(`widgets.select.objectPlaceholder`)"
        @input="(value) => $emit('input', value)"
        @search-change="(value) => {if (value && !isLoading) {this.debouncedSearchObject(value)}; }"
        @close="close"
        @remove="remove">

        <template #noResult>
          <div v-html="textNoResult"></div>
        </template>

        <template #noOptions> <div v-html="''"></div></template>

        <template slot="option" slot-scope="props">
          <div class="flex-row item">
            <div class="option-label-div"> <span class="option-label">{{ props.option.title }}</span></div>
            <div class="option-label-div"><span class="option-label">{{props.option.address}}</span></div>
          </div>
        </template>

        <template slot="tag" slot-scope="{ option, remove, index }">
            <span class="multiselect__tag" :key="index">
                                  <span v-text="option.title"></span>
                                  <i aria-hidden="true" tabindex="1" @keydown.enter.prevent="remove(option)"  @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
            </span>
        </template>

        <template slot="caret">
          <div class="lupa">
           <!-- <b-icon icon="search"></b-icon>-->
          </div>
        </template>

      </multiselect>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import Multiselect from 'vue-multiselect'
  import _debounce from 'lodash/debounce'

  export default {
    name: 'GroupSelectWidget',
    components: {Multiselect},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isLoading: false,
        textNoResult: this.$t('widgets.select.objectTextNoResult'),
        selectedValues: [],
        options: []
      }
    },
    mounted() {
      this.searchObjects()
    },
    created() {
      this.debouncedSearchObject = _debounce(this.searchObjects, 1000)
    },
    methods: {
      close() {
        this.$emit('select', this.selectedValues)
      },
      remove(element) {
        this.selectedValues = this.selectedValues.filter(el => el.id !== element.id)
        this.$emit('select', this.selectedValues)
      },
      limitText(count) {
        return  '+ ' + count
      },
      searchObjects (query) {
        this.isLoading = true
        this.options = []
        rest.get('/group/user',  {

        }).then(response => {
          this.options = response.data

          if (this.options && this.options.length > 0) {
            this.$emit('showGroupFilter')
          } else {
            this.$emit('hideGroupFilter')
          }
          this.isLoading = false
        })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }

    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .main {

  }
  .lupa {
    color: #666666;
    right: get-size(1);
    top: get-size(13);
    position: absolute;
    z-index: 20;
    font-size: get-size(20);
  }

  .item {
    border-bottom: get-size(1) solid #595959;
  }

  .option-label {
    white-space: normal;
    word-wrap: break-word;
  }
  .option-label-div {
    white-space: normal;
    word-wrap: break-word;
  }
</style>
