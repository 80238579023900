<template>
    <div class="report">
      <div>
        <b-button class="nav-item"  :class="{'active-nav-item ': currentReport === 1}" @click="currentReport = 1">{{$t(`report.onDate`)}}</b-button>
        <b-button  class="nav-item"  :class="{'active-nav-item ': currentReport === 2}" @click="currentReport = 2">{{$t(`report.byPeriod`)}}</b-button>
      </div>
      <report-on-date v-if="currentReport === 1"></report-on-date>
      <report-by-period v-if="currentReport === 2"></report-by-period>
    </div>
</template>

<script>
  import {BButton} from 'bootstrap-vue'
  import DefaultContainerScroll from '../DefaultContainerScroll'
  import {ACCESS_TOKEN, APP_TABS} from '../../constant'
  import 'vue2-datepicker/index.css'
  import ReportOnDate from './ReportOnDate'
  import ReportByPeriod from './ReportByPeriod'

  export default {
    components: {ReportByPeriod, ReportOnDate, BButton, DefaultContainerScroll},
    name: 'Reports',
    mounted() {
      this.reportDate = new Date()
      this.name = this.$currentUser ? this.$currentUser.email : ''
    },
    created() {
      window.addEventListener('resize', this.resizeHandler)
      this.$store.dispatch('SAVE_CURRENT_APP_TAB', APP_TABS.REPORTS)
    },
    destroyed() {
      window.removeEventListener('resize', this.resizeHandler)
    },
    data() {
      return {
        currentReport: 1
      }
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "src/assets/scss/main";

  .report {
    height: auto;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding: get-size(10) get-size(10) get-size(10) get-size(10);
  }

  .nav-item {
    width: get-size(250);
    border-radius: get-size(10);
    padding: get-size(5) get-size(10) get-size(5) get-size(10);
  }

  @media (max-width: 640px) {
    .nav-item {
      width: get-size(300);
    }
  }

  @media (max-width: 400px) {
    .nav-item {
      width: get-size(200);
    }
  }
</style>
