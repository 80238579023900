<template>
  <div>
    <div class="devices">
      <device  v-for="(item) in devices" :item='item'  :key="item.id"></device>
    </div>

    <div class="footer-container"  v-on:click="loadNextBatch">
      <span v-if="!loading && possibleLoading">{{$t(`common.load-text`)}} {{size}} {{$t(`common.items-text`)}} ...</span>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import {ACCESS_TOKEN, APP_TABS} from '../../constant'
import router from '../../router'
import WidgetContainer from '../widget/WidgetContainer'
import SettingComponent from '../common/SettingComponent'
import DefaultContainerScroll from '../DefaultContainerScroll'
import Device from './Device'
import {rest} from '../../api'

export default {
  components: {BButton, WidgetContainer, DefaultContainerScroll, SettingComponent, Device},
  name: 'DevicesContainer',
  props: {
    errorFilter: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.devices = []
    this.possibleLoading = true
    this.name = this.$currentUser ? this.$currentUser.email : ''
    this.fetchData()
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
    this.$store.dispatch('SAVE_CURRENT_APP_TAB', APP_TABS.DEVICES)
   // this.$store.dispatch('SAVE_DEVICE_FILTER', '')
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
    this.page = 0
    this.possibleLoading = true
  },
  data () {
    return {
      loading: false,
      possibleLoading: true,
      page: 0,
      size: 20,
      search: this.deviceFilter,
      name: '1',
      setting: false,
      widgetsKey: 1,
      item: {
        name: 'device'
      },
      devices: []
    }
  },
  computed: {
    groupFilter() {
      return this.$store.getters.GROUP_FILTER
    },
    deviceFilter() {
      return this.$store.getters.DEVICE_FILTER
    }
  },
  watch: {
    groupFilter: {
      handler: function (newVal, oldVal) {
        this.devices = []
        this.possibleLoading = true
        this.page = 0
        this.fetchData()
      }
    },
    deviceFilter: {
      handler: function (newVal, oldVal) {
        this.devices = []
        this.possibleLoading = true
        this.page = 0
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    loadNextBatch() {
      this.page++
      this.fetchData()
    },
    getParamsForGroupFilter() {
      let paramsObj = null
      if (this.groupFilter) {
        paramsObj = {
          params: {
            groupFilter: this.groupFilter
          }
        }
      }
      return paramsObj
    },
    fetchData() {
      let params =  this.getParamsForGroupFilter()
      if (params && params.params) {
        params.params.search = this.deviceFilter.simpleSearch
        params.params.page = this.page
        params.params.size = this.size
        params.params.errorFilter = this.deviceFilter.errorWidgetFilter
        params.params.batteryChargeFilter = this.deviceFilter.batteryChargeFilter
        params.params.connectionFilter = this.deviceFilter.connectionWidgetFilter
      } else {
        params = {
          params: {
            search: this.deviceFilter.simpleSearch,
            page: this.page,
            size: this.size,
            errorFilter: this.deviceFilter.errorWidgetFilter,
            batteryChargeFilter: this.deviceFilter.batteryChargeFilter,
            connectionFilter: this.deviceFilter.connectionWidgetFilter
          }
        }
      }
      this.loading = true
      rest.get('/devices/all', params).then(response => {
        const loadedData = response.data
        if ((loadedData.length === 0) || (loadedData.length < this.size)) {
          this.possibleLoading = false
        }
        this.devices =  this.devices.concat(loadedData)
        this.loading = false
      })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "src/assets/scss/main";

  .devices {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
</style>
