<template>
  <div>
    <multiselect
      v-model="filterValue"
      :deselect-label="$t(`widgets.select.delete`)"
      :selectLabel="$t(`widgets.select.selectItem`)"
      :selectedLabel="$t(`widgets.select.selectedLabel`)"
      track-by="id"
      :options="options"
      :searchable="false"
      :multiple="false"
      :loading="isLoading"
      :internal-search="false"
      :allow-empty="true"
      :close-on-select="true"
      :clear-on-select="false"
      :placeholder="$t(`widgets.select.search`)"
      @close="close"
      @remove="remove">

      <template #noResult>
        <div v-html="textNoResult"></div>
      </template>

      <template #noOptions>
        <div v-html="''"></div>
      </template>

      <template slot="option" slot-scope="props">
        <div class="flex-row item">
          <div class="option-label-div"><span class="option-label">{{ getAlias(props.option) }}</span></div>
        </div>
      </template>

      <template slot="tag" slot-scope="{ option, remove, index }">
            <span class="multiselect__tag" :key="index">
                                  <span  v-text=" getAlias(option)"></span>

                                  <i aria-hidden="true" tabindex="1" @keydown.enter.prevent="remove(option)"
                                     @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
            </span>
      </template>


      <template slot="singleLabel" slot-scope="{ option }">
        <span  class="selectLabel">
         {{ getAlias(option) }}
        </span>
      </template>


      <template slot="caret">
       <div class="lupa">
          <!-- <b-icon icon="search"></b-icon>-->
        </div>
      </template>

   <template slot="clear">
      <span @mousedown.prevent.stop="remove" v-if="filterValue">
        <i class="multiselect__tag-icon">
        </i>
      </span>
      </template>
    </multiselect>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import _debounce from 'lodash/debounce'
  import Multiselect from 'vue-multiselect'
  import {BFormInput} from 'bootstrap-vue'

  export default {
    name: 'SingleSelectFilterWidget',
    components: {Multiselect, BFormInput},
    props: {
      filterVal: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      options: []
    },
    data() {
      return {
        isLoading: false,
        textNoResult: this.$t('widgets.select.objectTextNoResult'),
        filterValue: null
      }
    },
    mounted() {

    },
    created() {
    },
    methods: {
      getAlias(option) {
        if (option) {
          if (option.needTranslation) {
            return option.aliasPrefix + this.$t(option.alias) + option.aliasSuffix
          } else {
            return option.aliasPrefix + option.alias + option.aliasSuffix
          }
        }
        return ''
      },
      close() {
        this.$emit('select', this.filterValue)
      },
      remove(element) {
        this.$emit('remove')
      }
    },
    watch: {
      filterVal: {
        handler: function (newVal, oldVal) {
          if (this.filterVal) {
            this.filterValue = this.options.filter(opt => opt.value === this.filterVal)
          } else {
            this.filterValue = null
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .main {

  }

  .multiselect__tag-icon {
    top: get-size(10);
    right: get-size(5);
    line-height: get-size(24);
    width: get-size(20);
  }

  .multiselect__tag-icon:hover {
    background: none !important;
  }

  /deep/.multiselect__tags {
    height: get-size(44);
    min-height: get-size(44);
  }

  .selectLabel {
    font-size: get-size(16);
    line-height: get-size(16);
  }

  .single-select {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      outline: none;
    }

    input:focus {
      outline: none;
    }
  }
</style>
