import {MODE_MAPPING} from '../constant'

export const common = {
  props: {
    groupFilter: {
      type: String,
      default: null
    }
  },
  computed: {
      mode() {
        return this.$store.getters.MODE
      }
  },
  watch: {
    groupFilter() {
      this.fetchData()
    }
  },
  methods: {
    getColor(colorSuffix) {
      if (MODE_MAPPING[this.mode]) {
        return MODE_MAPPING[this.mode][colorSuffix]
      }

      return MODE_MAPPING.WHITE[colorSuffix]
    },

    getParamsForGroupFilter() {
      let paramsObj = null
      if (this.groupFilter) {
        paramsObj = {
          params: {
            groupFilter: this.groupFilter
          }
        }
      }
      return paramsObj
    },

    fetchData() {
    }
  }
}
