<template>
  <div class="current-widget">
    <div class="header-t">
      <h2>{{$t(`widgets.water.title_big`)}}</h2>
    </div>
    <div class="multiselect-containet">
      <multiselect
        v-model="selectValueYear"
        :deselect-label= "$t(`widgets.select.cantSelect`)"
        track-by="name" label="name"
        :placeholder="$t(`widgets.select.selectYear`)"
        :selectLabel="$t(`widgets.select.selectItem`)"
        :selectedLabel="$t(`widgets.select.selectedLabel`)"
        :options="selectOptionsYear"
        :searchable="false"
        :allow-empty="false"
        @input="fetchData">

        <template slot="singleLabel" slot-scope="{ option }">{{ option.name }} </template>

      </multiselect>
    </div>
    <div class="chart-content scroller-firefox">
        <LineChart ref='chart1' :width="wwMonth" class="chart-style-month"
               :height="hhMonth" type="line" :chart-data="monthDataCollection" :options="optionsMonth"></LineChart>
    </div>
    <div class="header-t">
      <h2>{{$t(`widgets.water.title_big2`)}}</h2>
    </div>
    <div class="multiselect-containet">
      <multiselect
        v-model="selectValueMonth"
        :deselect-label= "$t(`widgets.select.cantSelect`)"
        track-by="name" label="name"
        :placeholder= "$t(`widgets.select.selectMonth`)"
        :selectLabel="$t(`widgets.select.selectItem`)"
        :selectedLabel="$t(`widgets.select.selectedLabel`)"
        :options="selectOptionsMonth"
        :searchable="false"
        :allow-empty="false"
        @input="fetchData">

        <template slot="singleLabel" slot-scope="{ option }">{{ option.name }} </template>

      </multiselect>
    </div>
    <div class="chart-content">
      <LineChart ref='chart2' :width="wwDay" class="chart-style-day"
                 :height="hhDay" :chart-data="dayDataCollection" :options="optionsDay"></LineChart>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import LineChart from '../chart/LineChart'
  import Multiselect from 'vue-multiselect'
  import {common} from '../../mixin/mixin.js'

  export default {
    name: 'WaterWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, LineChart, Multiselect},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        selectValueMonth: null,
        selectOptionsMonth: [
          {name: this.$t('common.month.full.1'),   value: 1},
          {name: this.$t('common.month.full.2'),   value: 2},
          {name: this.$t('common.month.full.3'),   value: 3},
          {name: this.$t('common.month.full.4'),   value: 4},
          {name: this.$t('common.month.full.5'),   value: 5},
          {name: this.$t('common.month.full.6'),   value: 6},
          {name: this.$t('common.month.full.7'),   value: 7},
          {name: this.$t('common.month.full.8'),   value: 8},
          {name: this.$t('common.month.full.9'),   value: 9},
          {name: this.$t('common.month.full.10'),  value: 10},
          {name: this.$t('common.month.full.11'),  value: 11},
          {name: this.$t('common.month.full.12'), value: 12}
        ],
        selectValueYear: null,
        selectOptionsYear: [],
        wwMonth: 200,
        hhMonth: 120,
        wwDay: 160,
        hhDay: 50,
        currentMothValue: 0,
        currentDayValue: 0,
        monthDataCollection: {},
        dayDataCollection: {},
        labelsForMonthData: [
          this.$t('common.month.short.1'),
          this.$t('common.month.short.2'),
          this.$t('common.month.short.3'),
          this.$t('common.month.short.4'),
          this.$t('common.month.short.5'),
          this.$t('common.month.short.6'),
          this.$t('common.month.short.7'),
          this.$t('common.month.short.8'),
          this.$t('common.month.short.9'),
          this.$t('common.month.short.10'),
          this.$t('common.month.short.11'),
          this.$t('common.month.short.12')
        ],
        valuesForMonthData: [],
        optionsMonth: {},
        labelsForDayData: [],
        valuesForDayData: [],
        optionsDay: {}
      }
    },
    mounted() {
      this.fillYearsOptions()
      this.fillMonthOptions()

      this.optionsMonth = {
          responsive: true,
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: '#fff',
            titleFontColor: '#555',
            bodyFontColor: '#555',
            footerFontColor: '#555',
            borderWidth: 1,
            borderColor: '#00000033',
            fontColor: 'red'
          },
          interaction: {
            intersect: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                lineWidth: 1,
                color: this.getColor('axis_color'),
                zeroLineColor: this.getColor('axis_color')
              },
              scaleLabel: {
                display: false,
                labelString: 'рік'
              },
              ticks: {
                beginAtZero: false,
                autoSkip: false,
                padding: -3,
                maxRotation: 0,
                minRotation: 0,
                showLabelBackdrop: false,
                fontSize: 9,
                fontColor: this.getColor('scale_color')
              }
            }],

            yAxes: [{
              gridLines: {
                beginAtZero: false,
                lineWidth: 1,
                drawBorder: false,
                color: this.getColor('axis_color'),
                zeroLineColor: this.getColor('axis_color')
              },
              scaleLabel: {
                display: false,
                labelString: 'м. куб.'
              },
              ticks: {
                beginAtZero: true,
                maxRotation: 0,
                minRotation: 0,
                padding: 0,
                stepSize: 50,
                fontSize: 9,
                fontColor: this.getColor('scale_color')
              }
            }]
          }
      }
      this.optionsDay = {
          tooltips: {
            backgroundColor: '#fff',
            titleFontColor: '#555',
            bodyFontColor: '#555',
            footerFontColor: '#555',
            borderWidth: 1,
            borderColor: '#00000033',
            fontColor: 'red'
          },
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                lineWidth: 1,
                color: this.getColor('axis_color'),
                zeroLineColor: this.getColor('axis_color')
              },
              scaleLabel: {
                display: false,
                labelString: this.$t('widgets.water.title_big')
              },
              ticks: {
                autoSkip: false,
              //  min: 1,
               // max: 31,
                stepSize: 1,
               // fixedStepSize: 1,
                maxRotation: 0,
                minRotation: 0,
                padding: -3,
            //    showLabelBackdrop: false,
                beginAtZero: true,
                fontSize: 9,
                fontColor: this.getColor('scale_color')
              }
            }],

            yAxes: [{
              gridLines: {
                lineWidth: 1,
                drawBorder: false,
                color: this.getColor('axis_color'),
                zeroLineColor: this.getColor('axis_color')
              },
              scaleLabel: {
                display: false,
                labelString: 'м. куб.'
              },
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                padding: 0,
                beginAtZero: true,
                stepSize: 2,
                min: 0,
                fontSize: 9,
                fontColor: this.getColor('scale_color')
              }
            }]
          }
      }
      this.fetchData()
    },
    methods: {
      fillMonthOptions() {
        const currentMonth = (new Date().getMonth() + 1)
        this.selectValueMonth = this.selectOptionsMonth.find(item => item.value === currentMonth)
      },
      fillYearsOptions() {
        const countOfYear = 5
        const yearStr = ` ${this.$t('widgets.water.year')}`
        const currentYear =  new Date().getFullYear()

        for (let i = 0; i < countOfYear; i++) {
          const item = {name: ((currentYear - i) + yearStr), value: (currentYear - i)}
          this.selectOptionsYear.push(item)
          if (item.value === currentYear) {
            this.selectValueYear = item
          }
        }
      },
      fetchData() {
        let params =  this.getParamsForGroupFilter()
        if (params && params.params) {
          params.params.year = this.selectValueYear.value
          params.params.month = this.selectValueMonth.value
        } else {
          params = {
            params: {
              year: this.selectValueYear.value,
              month: this.selectValueMonth.value
            }
          }
        }
        rest.get('/widget/water', params).then(response => {
          this.fillMonthData(response.data.monthDate)
          this.fillDayData(response.data.dayDate)
        })
          .catch(error => {
            console.log(error)
          })
      },

      fillMonthData(monthDate) {
        this.valuesForMonthData = []
        const currentMonth = (new Date().getMonth() + 1)

        for (let i = 1; i <= 12; i++) {
          const val = monthDate.find(el => el.group == i)
          if (val) {
            this.valuesForMonthData.push(val.value)
            if (currentMonth == i) {
              this.currentMothValue = val.value
            }
          } else {
            this.valuesForMonthData.push(0)
          }
        }

        this.optionsMonth.scales.xAxes[0].scaleLabel.labelString = (`${new Date().getFullYear()} рік`)

        this.monthDataCollection = {
          labels: this.labelsForMonthData,
          datasets: [
            {
              data: this.valuesForMonthData,
              pointBackgroundColor: this.getColor('graph_background_color'),
              pointBorderWidth: 2,
              pointStyle: 'circle',
              fill: false,
              showLine: true,
              borderColor: this.getColor('graph_color'),
              tension: 0,
              borderWidth: 2,
              borderJoinStyle: 'round',
              segment: 'before'
          //    borderDash: [1]
            }
          ]
        }
      },

      fillDayData(dayDate) {
        this.labelsForDayData = []
        this.valuesForDayData = []
        const today = new Date()
        const currentDay = today.getDate()
        const maxCountDaysInCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
        for (let i = 1; i <= maxCountDaysInCurrentMonth; i++) {
          const val = dayDate.find(el => el.group == i)
          this.labelsForDayData.push(i)
          if (val) {
            this.valuesForDayData.push(val.value)
            if (currentDay == i) {
              this.currentDayValue = val.value
            }
          } else {
            this.valuesForDayData.push(0)
          }
        }

        this.dayDataCollection = {
          labels: this.labelsForDayData,
          datasets: [
            {
              data: this.valuesForDayData,
              pointBackgroundColor: this.getColor('graph_background_color'),
              pointBorderWidth: 2,
              pointStyle: 'circle',
              fill: false,
              showLine: true,
              borderColor: this.getColor('graph_color'),
              tension: 0,
              borderWidth: 2,
              borderJoinStyle: 'round',
              segment: 'before'
            //  borderDash: [1]
            }
          ]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .multiselect-containet {
    padding-top: get-size(10);
  }

  .chart-style {
    border-radius: get-size(10);
    width: 160%;
  }

  .chart-style-month {
    border-radius: get-size(10);
    width: 99%;
  }

  .chart-style-day {
    border-radius: get-size(10);
    width: 180%;
  }

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .chart-content {
    width: 100%;
    padding-bottom: get-size(10);
    margin-top: get-size(15);
    overflow-x: auto;
  }

  .chart-content::-webkit-scrollbar {
    width: get-size(4);
    height: get-size(4);
  }

  /* Track */
  .chart-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 get-size(5);
    border-radius: get-size(10);
  }
  /* Handle */
  .chart-content::-webkit-scrollbar-thumb {
    border-radius: get-size(4);
    top: get-size(-100)
  }

  .scroller-firefox {
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .data-content {
    padding-top: get-size(15);
  }

  .header-t {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    h1 {
      font-size: get-size(35);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(24);
      text-align: center;
      margin: 0;
      margin-top: get-size(13);
      margin-bottom: get-size(13);
    }
    h2 {
      font-size: get-size(22);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(24);
      text-align: center;
      margin: 0;
    }
    h3 {
      font-size: get-size(14);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(24);
      text-align: center;
      margin: 0;
      margin-bottom: get-size(26);
    }
  }

</style>
