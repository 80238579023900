<template>
  <div class="current-widget">
    <div class='main_header'>
      <div class="big">
        <span>{{$t(`widgets.covering.title_big`)}}</span>
      </div>
    </div>
    <div class="chart-content">
      <BarChart :chart-data="dataCollection" :options="chartOptions" class="chart-style"></BarChart>
    </div>
    <div class="data-content">
      <ChartDescriptionItem v-for="(item, index) in dataKeys.data"
        :iconColor="item.color"
        :text="item.keyAlias"
        :key="index"
        :value="item.value"
        :percent="item.percent"
      ></ChartDescriptionItem>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import BarChart from '../chart/BarChart'
  import VerticalLineChartItem from '../widget/util/VerticalLineChartItem'
  import ChartDescriptionItem from '../widget/util/ChartDescriptionItem'
  import {common} from '../../mixin/mixin.js'

  export default {
    name: 'CoveringWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, BarChart, ChartDescriptionItem, VerticalLineChartItem},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        totalCount: 0,
        dataKeys: {},
        dataCollection: {},
        chartOptions: {}
      }
    },
    mounted() {
      this.chartOptions = {
          barRoundness: 10,
          tooltips: {
            backgroundColor: '#fff',
            titleFontColor: '#555',
            bodyFontColor: '#555',
            footerFontColor: '#555',
            borderWidth: 1,
            borderColor: '#00000033',
            fontColor: 'red'
          },
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
                lineWidth: 1,
                color: this.getColor('axis_color'),
                zeroLineColor: this.getColor('axis_color')
              },
              scaleLabel: {
                display: false,
                labelString: 'рік'
              },
              ticks: {
                autoSkip: false,
                //  min: 1,
                // max: 31,
                stepSize: 1,
                // fixedStepSize: 1,
                maxRotation: 0,
                minRotation: 0,
                padding: -3,
                //    showLabelBackdrop: false,
                beginAtZero: true,
                fontSize: 9,
                fontColor: this.getColor('scale_color')
              }
            }],

            yAxes: [{
              gridLines: {
                lineWidth: 1,
                drawBorder: false,
                color: this.getColor('axis_color'),
                zeroLineColor: this.getColor('axis_color')
              },
              scaleLabel: {
                display: false,
                labelString: 'м. куб.'
              },
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                padding: 0,
                beginAtZero: true,
                stepSize: 2,
                min: 0,
                fontSize: 9,
                fontColor: this.getColor('scale_color')
              }
            }]
          }
        }
      this.fetchData()
    },
    methods: {
      initData() {
        this.totalCount = 0
        this.dataKeys = {data: [
            {key: 'EXCELLENT', aliasShort: this.$t('widgets.covering.description_short_good'), keyAlias: this.$t('widgets.covering.description_good'), value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 'GOOD', aliasShort: this.$t('widgets.covering.description_short_medium'), keyAlias: this.$t('widgets.covering.description_medium'), value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 'AVERAGE', aliasShort: this.$t('widgets.covering.description_short_medium2'), keyAlias: this.$t('widgets.covering.description_medium2'), value: 0, percent: 0, color: this.getColor('yellow_color')},
            {key: 'LIMIT', aliasShort: this.$t('widgets.covering.description_short_bad'), keyAlias: this.$t('widgets.covering.description_bad'), value: 0, percent: 0, color: this.getColor('red_color')}
          ]}
      },
      fetchData() {
        this.initData()
        rest.get('/widget/covering', this.getParamsForGroupFilter()).then(response => {
          this.dataCollection.datasets = []
          this.dataCollection.labels = []
          const values = []
          for (const item of this.dataKeys.data) {
            const founded = response.data.find(el => el.cover === item.key)
            let count = 0
            if (founded) {
              count = founded.count
            }
            item.value = count
            values.push(count)
          }

          for (const item of this.dataKeys.data) {
            item.percent = Math.round(item.value / this.dataKeys.data.map(i => i.value).reduce((val1, val2) => val1 + val2) * 100)
          }

          this.dataCollection = {
            labels: this.dataKeys.data.map(item => item.aliasShort),
            datasets: [
              {
                borderRadius: 10,
                backgroundColor: this.dataKeys.data.map(item => item.color),
                data: values
              }
            ]
          }
        })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }

  .data-content {
    padding-top: get-size(20);
  }

  .chart-style {
    border-radius: get-size(10);
  }
</style>
