<template>
  <div class="current-widget">
    <div class='main_header'>
      <div class="big">
        <span>{{$t(`widgets.map.title_big`)}}</span>
      </div>
      <div class="chart-content">
        <MeterSelectWidget  @select="selectMeters"></MeterSelectWidget>
      </div>
      <google-map :locations="locations" :feedLoaded="feedLoaded"></google-map>
    </div>
  </div>
</template>

<script>

  import MeterSelectWidget from '../common/MeterSelectWidget'
  import GoogleMap from './util/GoogleMap'

  export default {
    name: 'MapWidget',
    components: {GoogleMap, MeterSelectWidget},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        mapTypeId: 'styledMapType',
        locations: [],
        feedLoaded: false
      }
    },
    mounted() {
    },
    methods: {
      selectMeters(meters) {
        this.feedLoaded = true
        this.locations = meters
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }



</style>
