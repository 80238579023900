<template>
    <div class="widgets">
      <WidgetContainer>
        <ErrorWidget :key="widgetsKey" :groupFilter="groupFilter"></ErrorWidget>
      </WidgetContainer>
      <WidgetContainer>
        <BatteryChargeWidget :key="widgetsKey" :groupFilter="groupFilter"></BatteryChargeWidget>
      </WidgetContainer>
      <WidgetContainer>
        <ConnectionWidget :key="widgetsKey" :groupFilter="groupFilter"></ConnectionWidget>
      </WidgetContainer>
      <WidgetContainer>
        <StatusWidget :key="widgetsKey" :groupFilter="groupFilter"></StatusWidget>
      </WidgetContainer>
      <WidgetContainer>
        <CheckingWidget :key="widgetsKey" :groupFilter="groupFilter"></CheckingWidget>
      </WidgetContainer>
      <WidgetContainer>
        <WaterWidget :key="waterWidgetKey" :groupFilter="groupFilter"></WaterWidget>
      </WidgetContainer>
      <WidgetContainer>
        <CoveringWidget :key="widgetsKey" :groupFilter="groupFilter"></CoveringWidget>
      </WidgetContainer>
      <WidgetContainer>
        <SubscriptionWidget :key="widgetsKey" :groupFilter="groupFilter"></SubscriptionWidget>
      </WidgetContainer>
      <WidgetContainer>
        <MapWidget :key="widgetsKey"  :groupFilter="groupFilter"></MapWidget>
      </WidgetContainer>
      <div class="footer-container" >

      </div>
    </div>
</template>

<script>
import WidgetContainer from '../widget/WidgetContainer'
import DefaultContainerScroll from '../DefaultContainerScroll'
import ErrorWidget from '../widget/ErrorWidget'
import BatteryChargeWidget from '../widget/BatteryChargeWidget'
import ConnectionWidget from '../widget/ConnectionWidget'
import WaterWidget from '../widget/WaterWidget'
import CheckingWidget from '../widget/CheckingWidget'
import StatusWidget from '../widget/StatusWidget'
import CoveringWidget from '../widget/CoveringWidget'
import SubscriptionWidget from '../widget/SubscriptionWidget'
import MapWidget from '../widget/MapWidget'
import SettingComponent from './SettingComponent'
import { BButton } from 'bootstrap-vue'
import {ACCESS_TOKEN, APP_TABS} from '../../constant'
import router from '../../router'

export default {
  components: {BButton, WidgetContainer, DefaultContainerScroll, ErrorWidget, BatteryChargeWidget,
    ConnectionWidget, WaterWidget, CheckingWidget, StatusWidget,
    CoveringWidget, SubscriptionWidget, MapWidget, SettingComponent},
  name: 'Dashboard',
  mounted() {
    this.name = this.$currentUser ? this.$currentUser.email : ''
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
    this.$store.dispatch('SAVE_CURRENT_APP_TAB', APP_TABS.ANALYTICS)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  data () {
    return {
      name: '',
      waterWidgetKey: 1,
      setting: false,
      widgetsKey: 1
    }
  },
  computed: {
    groupFilter() {
      return this.$store.getters.GROUP_FILTER
    }
  },
  methods: {
    settingChanged() {
      this.widgetsKey++
      this.waterWidgetKey++
    },
    resizeHandler(e) {
      this.waterWidgetKey++
    },
    logOut() {
      localStorage.removeItem(ACCESS_TOKEN)
      router.push('login')
    }
  }
}
</script>

<style lang="scss">
  /* Custom Styles */
  @import "src/assets/scss/main";

  .widgets {
    width: 100%;
    height: 100%;
    display: inline;
  }
</style>
