import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../component/common/Dashboard'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import Report from '../component/report/ReportOnDate'
import SettingComponent from '../component/common/SettingComponent'
import DevicesContainer from '../component/device/DevicesContainer'
import DeviceInfo from '../component/device/DeviceInfo'
import Reports from '../component/report/Reports'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/analytics',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/report',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/settings',
    name: 'Setting',
    component: SettingComponent
  },
  {
    path: '/devices',
    name: 'Devices',
    component: DevicesContainer,
    props: (route) => {
      return {
        errorFilter: route.query.errorFilter
      }
    }
  },
  {
    path: '/devices/:id',
    name: 'deviceInfo',
    component: DeviceInfo,
    props: (route) => {
      return {
        id: route.params.id
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
