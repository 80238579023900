<template>
<div id="app" v-bind:class="mode">
  <DefaultContainerScroll>
    <div class="app-content">
      <div class="main_container">
        <div class="header-container">
          <div class="header" v-if="currentUserPresented">
            <div class="logo1">
              <b-img blank></b-img>
            </div>
            <div class="logo2">
              <b-button  id="logOutBtn" v-on:click="showExitPopup = true" class="action-btn">{{$t(`view.widgets.logOut_btn`)}}</b-button>
            </div>
          </div>
          <app-nav-bar  v-if="currentUserPresented"></app-nav-bar>
          <div class="app-flter">
            <div class="select-wraper">
              <group-select-widget
                v-show="showGroupFilter && currentUserPresented && ((currentTab === APP_TABS.ANALYTICS) || (currentTab === APP_TABS.DEVICES))"
                @hideGroupFilter="showGroupFilter=false"
                @showGroupFilter="showGroupFilter=true"
                @select="selectObjects"></group-select-widget>

            </div>
            <div class="filter-wrapper" v-show="currentUserPresented && ( (currentTab === APP_TABS.DEVICES))">
              <iot-device-filter-container
                :showSimpleSearch="currentUserPresented && ( (currentTab === APP_TABS.DEVICES))">

              </iot-device-filter-container>
            </div>
          </div>
        </div>
        <confirm-popup @close="showExitPopup = false" @confirm="logOut" v-show="showExitPopup"
          :title="$t(`confirm-popup.exit-title`)"
          :question="name + ',' + $t(`confirm-popup.exit-question`)"
        >
        </confirm-popup>
        <div class="content">
          <router-view></router-view>
       </div>
      </div>
    </div>
  </DefaultContainerScroll>
</div>

</template>

<script>
import Vue from 'vue'
import {BButton, BootstrapVue, IconsPlugin, BIcon} from 'bootstrap-vue'
import Vuebar from 'vuebar'
import { common } from '../src/mixin/mixin.js'
import {ACCESS_TOKEN, APP_TABS} from './constant'
import router from './router'
import VueExcelEditor from 'vue-excel-editor'
import AppNavBar from './component/common/AppNavBar'
import ConfirmPopup from './component/common/ConfirmPopup'
import DefaultContainerScroll from './component/DefaultContainerScroll'
import GroupSelectWidget from './component/common/GroupSelectWidget'
import IotDeviceFilterContainer from './component/common/IotDeviceFilterContainer'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuebar)
Vue.use(VueExcelEditor)

export default {
  name: 'app',
  mixins: [common],
  components: {DefaultContainerScroll, BIcon, AppNavBar,
    ConfirmPopup, GroupSelectWidget, IotDeviceFilterContainer},
  mounted() {
    this.name = this.$currentUser ? this.$currentUser.email : ''
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
    this.currentUserPresented = !!this.$currentUser
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  data () {
    return {
      name: '',
      waterWidgetKey: 1,
      widgetsKey: 1,
      currentUserPresented: true,
      showExitPopup: false,
      APP_TABS,
      showGroupFilter: true
    }
  },
  computed: {
    currentTab() {
      return this.$store.getters.CURRENT_APP_TAB
    }
  },
  methods: {
    selectObjects(groups) {
      if (groups && groups.length > 0) {
        this.$store.dispatch('SAVE_GROUP_FILTER', groups.map(item => item.id).join())
      } else {
        this.$store.dispatch('SAVE_GROUP_FILTER', null)
      }
    },
    resizeHandler(e) {
      this.waterWidgetKey++
    },
    logOut() {
      this.showExitPopup = false
      localStorage.removeItem(ACCESS_TOKEN)
      router.push('login')
      this.currentUserPresented = false
    }
  }
}
</script>

<style lang="scss">
  /* Custom Styles */
  @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
  @import "assets/scss/main";

  #app {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding: 8px;
  }

  html {
    overflow-x: hidden;
    overflow-y: hidden;
    font-size: 1.25vw;
    font-family: 'Roboto', sans-serif;
  }

  body {
    font-size: 1.25vw;
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }

  .app-content {
    width: 100%;
    margin: auto;
  }

  #nav {

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .multiselect {
    width: 100%;

  }

  .select-wraper {
    width: 40%;
    padding-left: 60%;
  }

  .filter-wrapper {
    display: flex;
    margin-top: get-size(10);
    width: 100%;
  }

  @media (max-width: 640px) {
    .select-wraper {
      width: 100%;
      padding-left: 0px;
    }
    html {
      font-size: 2vw;
    }

    body {
      font-size: 2vw;
    }
  }

  @media (max-width: 400px) {
    .select-wraper {
      width: 100%;
      padding-left: 0px;
    }
    html {
      font-size: 3.9vw;
    }

    body {
      font-size: 3.9vw;
    }
  }

  .app-flter {
    width: 98%;
  }

  .widgets {
    width: 100%;
    height: 100%;
    display: inline;
  }
  .header {
    height: get-size(55);
    width: 98%;
    display: flex;
    .logo1 {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        display: block;
        max-width:get-size(110);
        max-height:get-size(57);
        min-width:get-size(110);
        min-height:get-size(57);
        width: auto;
        height: auto;
        margin: 0;
      }
    }
    .logo2 {
      cursor: pointer;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        display: block;
        max-width:get-size(24);
        max-height:get-size(15);
        min-width:get-size(24);
        min-height:get-size(15);
        width: auto;
        height: auto;
        margin: 0;
      }
    }
  }
  .content{
    position: relative;
  }
  .action-btn {
    cursor: pointer;
    border-width: get-size(1);
    border-radius: get-size(5);
    padding: get-size(5) get-size(15) get-size(5) get-size(15);
    margin-right: get-size(20);
  }

  .header-container {
    padding-right: get-size(15);
    margin-bottom: get-size(10);
  }

</style>
