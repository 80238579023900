import axios from 'axios'
import {ACCESS_TOKEN} from './constant'
import Vue from 'vue'
import {i18n} from './plugins/i18n'
import store from './store'

export const rest = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`
})

if (localStorage.getItem(ACCESS_TOKEN)) {
  rest.defaults.headers.common.Authorization = localStorage.getItem(ACCESS_TOKEN)
}

rest.defaults.timeout = 7000

export function initCurrentUser(successCallback, failureCallback) {
  rest.get('/user/current').then(response => {
    Vue.prototype.$currentUser = response.data
    i18n.locale = response.data.locale
    store.dispatch('SAVE_MODE', response.data.mode)
    if (successCallback``) {
      successCallback()
    }
  }).catch(error => {
    if (failureCallback) {
      failureCallback()
    }
  })
}
