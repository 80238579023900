<template>
  <div class="current-widget">
    <div class='main_header'>
      <div class="big">
        <span>{{$t(`widgets.battery_charge.title_big`)}}</span>
      </div>
      <div class="small">
        <span>{{$t(`widgets.battery_charge.title_small`)}}</span>
      </div>
    </div>
    <div class="chart-content">
      <template v-for="(item, index) in dataKeys.data">
        <VerticalLineChartItem
                               :key="item.key"
                               :keyVal="(''+item.key.toFixed(1))"
                               :value="item.value"
                               :percent="item.percent"
                               :indicatorColor="item.color"
                               :clickable="true"
                               @click="goOnDeviceView(POWER_LEVEL_WIDGET_FILTER_VALUES[index])">
        </VerticalLineChartItem>
      </template>
    </div>
    <div class="data-content">
      <ChartDescriptionItem
        :iconColor="getColor('green_color')"
        :text="$t(`widgets.battery_charge.description_good`)"
        :value="normalChargeCount"
        :percent="normalChargeCountPercent"
        :clickable="true"
        @click="goOnDeviceView(POWER_LEVEL_WIDGET_FILTER_VALUES[9])"
      ></ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('blue_color')"
        :text="$t(`widgets.battery_charge.description_bad`)"
        :value="badChargeCount"
        :percent="badChargeCountPercent"
        :clickable="true"
        @click="goOnDeviceView(POWER_LEVEL_WIDGET_FILTER_VALUES[10])"
      ></ChartDescriptionItem>
    </div>
  </div>
</template>

<script>

  import {rest} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import VerticalLineChartItem from '../widget/util/VerticalLineChartItem'
  import ChartDescriptionItem from '../widget/util/ChartDescriptionItem'
  import {common} from '../../mixin/mixin.js'
  import {POWER_LEVEL_WIDGET_FILTER_VALUES} from '../../apiTypeConstant'


  export default {
    name: 'BatteryChargeWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, VerticalLineChartItem, ChartDescriptionItem},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        POWER_LEVEL_WIDGET_FILTER_VALUES,
        normalChargeCount: 0,
        badChargeCount: 0,
        normalChargeCountPercent: 0,
        badChargeCountPercent: 0,
        badCharge: 3.0,
        dataKeys: {}
      }
    },
    mounted() {
      this.currentLocale = this.$currentUser.locale
      this.fetchData()
    },
    methods: {
      goOnDeviceView(filterValue) {
        this.$store.dispatch('SAVE_BATTERY_CHARGE_WIDGET_DEVICE_FILTER', filterValue.value)
        this.$router.push({ path: ('devices')})
      },
      initData() {
        this.normalChargeCount = 0
        this.badChargeCount = 0
        this.normalChargeCountPercent = 0
        this.badChargeCountPercent = 0
        this.dataKeys = {max: 3.6, min: 2.8, data: [
            {key: 3.6, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3.5, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3.4, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3.3, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3.2, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3.1, value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 3.0, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 2.9, value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 2.8, value: 0, percent: 0, color: this.getColor('blue_color')}
          ]}
      },
      findDataKeyItem(item) {
        if (item.charge < this.dataKeys.min) {
          return this.dataKeys.data.find(el => el.key == this.dataKeys.min)
        }

        if (item.charge > this.dataKeys.max) {
          return this.dataKeys.data.find(el => el.key == this.dataKeys.max)
        }

        return this.dataKeys.data.find(el => el.key == item.charge)
      },
      fetchData() {
        this.initData()

        rest.get('/widget/battery', this.getParamsForGroupFilter()).then(response => {
          for (const item of response.data) {
            const insertTo = this.findDataKeyItem(item)
            if (insertTo) {
              insertTo.value += item.metersCount
              if (item.charge <= this.badCharge) {
                this.badChargeCount += item.metersCount
              } else {
                this.normalChargeCount += item.metersCount
              }
            }
          }

          for (const item of this.dataKeys.data) {
            item.percent = 0
            if ((this.normalChargeCount + this.badChargeCount) > 0) {
              item.percent = Math.round(item.value / (this.normalChargeCount + this.badChargeCount) * 100)
            }
          }
          this.normalChargeCountPercent = 0
          this.badChargeCountPercent = 0
          if ((this.badChargeCount + this.normalChargeCount) > 0) {
            this.normalChargeCountPercent = this.normalChargeCount / (this.badChargeCount + this.normalChargeCount) * 100
          }
          if ((this.badChargeCount + this.normalChargeCount) > 0) {
            this.badChargeCountPercent = this.badChargeCount / (this.badChargeCount + this.normalChargeCount) * 100
          }
        })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }

  .data-content {
    padding-top: get-size(20);
  }

</style>
