<template>
  <div class="current-widget">
    <div class="header-t">
      <h2>{{$t(`widgets.error.title_big`)}}</h2>
      <h1>{{totalMetersCount}}</h1>
      <h3>{{$t(`widgets.error.title_small`)}}</h3>
    </div>
    <div class="chart-content">
      <DoughnutChart :chart-data="dataCollection" :options="options"></DoughnutChart>
    </div>
    <div class="data-content">
        <ChartDescriptionItem
          :iconColor="getColor('green_color')"
          :text="$t(`widgets.error.description_good`)"
          :value="metersWithoutErrorsCount"
          :percent="metersWithoutErrorsCount/totalMetersCount * 100"
          :clickable="true"
          @click="goOnDeviceView(ERROR_WIDGET_FILTER_VALUES[0])"
        ></ChartDescriptionItem>

        <ChartDescriptionItem
          :iconColor="getColor('blue_color')"
          :text="$t(`widgets.error.description_medium`)"
          :value="lostConnectionMetersCount"
          :percent="lostConnectionMetersCount/totalMetersCount * 100"
          :clickable="true"
          @click="goOnDeviceView(ERROR_WIDGET_FILTER_VALUES[2])"
        ></ChartDescriptionItem>

        <ChartDescriptionItem
          :iconColor="getColor('red_color')"
          :text="$t(`widgets.error.description_bad`)"
          :value="metersWithErrorsCount"
          :percent="metersWithErrorsCount/totalMetersCount * 100"
          :clickable="true"
          @click="goOnDeviceView(ERROR_WIDGET_FILTER_VALUES[1])"
        ></ChartDescriptionItem>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import DoughnutChart from '../chart/DoughnutChart'
  import ChartDescriptionItem from '../widget/util/ChartDescriptionItem'
  import {common} from '../../mixin/mixin.js'
  import {ERROR_WIDGET_FILTER_VALUES} from '../../apiTypeConstant'

  export default {
    name: 'ErrorWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, DoughnutChart, ChartDescriptionItem},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        ERROR_WIDGET_FILTER_VALUES,
        metersWithoutErrorsCount: 0,
        metersWithErrorsCount: 0,
        lostConnectionMetersCount: 0,
        totalMetersCount: 0,
        dataCollection: {},
        options: {
          tooltips: {
            backgroundColor: '#fff',
            titleFontColor: '#555',
            bodyFontColor: '#555',
            footerFontColor: '#555',
            borderWidth: 1,
            borderColor: '#00000033',
            fontColor: 'red'
          },
          responsive: true,
          legend: {
            display: false
          },
          onClick: (evt, arr) => this.goOnDeviceView(this.ERROR_WIDGET_FILTER_VALUES[arr[0]._index])
        }
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      goOnDeviceView(filterValue) {
        this.$store.dispatch('SAVE_ERROR_WIDGET_DEVICE_FILTER', filterValue.value)
        this.$router.push({ path: ('devices')})
      },
      initData() {
        this.metersWithoutErrorsCount = 0
        this.metersWithErrorsCount = 0
        this.lostConnectionMetersCount = 0
        this.totalMetersCount = 0
        this.dataCollection.labels = []
        this.dataCollection.datasets = []
      },
      fetchData() {
        this.initData()
        rest.get('/widget/error', this.getParamsForGroupFilter()).then(response => {
          this.metersWithoutErrorsCount = response.data.metersWithoutErrorsCount
          this.metersWithErrorsCount = response.data.metersWithErrorsCount
          this.lostConnectionMetersCount = response.data.lostConnectionMetersCount
          this.totalMetersCount = response.data.totalMetersCount

          this.dataCollection = {
            labels: [this.$t('widgets.error.ok'), this.$t('widgets.error.error'), this.$t('widgets.error.no_connection')],
            datasets: [
              {
                backgroundColor: [this.getColor('green_color'), this.getColor('red_color'), this.getColor('blue_color')],
                data: [this.metersWithoutErrorsCount, this.metersWithErrorsCount, this.lostConnectionMetersCount],
                borderWidth: 0
              }
            ]
          }
        })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .chart-content {
    width: 80%;
    margin-left: 10%;
    padding-bottom: get-size(25);
  }

  .data-content {
    padding-top: get-size(15);
  }

  .header-t {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    h1 {
      font-size: get-size(35);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(24);
      text-align: center;
      margin: 0;
      margin-top: get-size(13);
      margin-bottom: get-size(13);
    }
    h2 {
      font-size: get-size(22);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(24);
      text-align: center;
      margin: 0;
    }
    h3 {
      font-size: get-size(14);
      font-style: normal;
      font-weight: 400;
      line-height: get-size(24);
      text-align: center;
      margin: 0;
      margin-bottom: get-size(26);
    }
  }

</style>
