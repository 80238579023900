<template>
   <div class="widget">
     <slot></slot>
   </div>
</template>

<script>

    import {rest, initCurrentUser} from '../../api'
    import DefaultContainerScroll from '../../component/DefaultContainerScroll'
    import {BFormInput} from 'bootstrap-vue'
    import {ACCESS_TOKEN} from '../../constant'

    export default {
        name: 'WidgetContainer',
        components: {BFormInput, DefaultContainerScroll},
        props: {
          name: {
            type: String,
            default: ''
          }
        },
        methods: {
        }
    }
</script>

<style lang="scss">
  /* Custom Styles */
  @import "../../assets/scss/main";

  .widget {
    display: inline-block;
    vertical-align: top;
    width: 32.7%;
    min-width: 32.7%;
   // height: get-size(650);
    height: auto;

    box-sizing: border-box;
    border-radius: get-size(16);
    padding-top: get-size(29);
    padding-bottom: get-size(29);
    padding-right: get-size(15);
    padding-left: get-size(15);
    margin-right: get-size(8);
    margin-bottom: get-size(4);
  }


  @media (max-width: 640px) {
    .widget {
      width: 49%;
      min-width: 49%;
      //height: get-size(900);
      height: auto;

    }
  }

  @media (max-width: 400px) {
    .widget {
      width: 98%;
      min-width: 98%;
      height: auto;
    }
  }
</style>
