<template>
  <div class="current-widget">
    <div class='main_header'>
      <div class="big">
        <span>{{$t(`widgets.status.title_big`)}}</span>
      </div>
      <div class="small">
        <span>{{$t(`widgets.status.title_small`)}}</span>
      </div>
    </div>
    <div class="chart-content">
      <template v-for="(item, index) in dataKeys.data">
        <VerticalLineChartItem
          :keyWidh="'27%'"
          :indicatorBgWidh="'49%'"
          :key="index"
          :keyVal="item.keyAlias"
          :value="item.value"
          :percent="item.percent"
          :indicatorColor="item.color">
        </VerticalLineChartItem>
      </template>
    </div>
    <div class="data-content" v-if="dataKeys.data">
      <ChartDescriptionItem
        :iconColor="getColor('green_color')"
        :text="$t(`widgets.status.description_good`)"
        :value="dataKeys.data[0].value"
        :percent="dataKeys.data[0].percent"
      ></ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('blue_color')"
        :text="$t(`widgets.status.description_medium`)"
        :value="dataKeys.data[1].value"
        :percent="dataKeys.data[1].percent"
      ></ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('yellow_color')"
        :text="$t(`widgets.status.description_medium2`)"
        :value="dataKeys.data[2].value"
        :percent="dataKeys.data[2].percent"
      ></ChartDescriptionItem>

      <ChartDescriptionItem
        :iconColor="getColor('red_color')"
        :text="$t(`widgets.status.description_bad`)"
        :value="dataKeys.data[3].value"
        :percent="dataKeys.data[3].percent"
      ></ChartDescriptionItem>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import DefaultContainerScroll from '../../component/DefaultContainerScroll'
  import {BFormInput} from 'bootstrap-vue'
  import DoughnutChart from '../chart/DoughnutChart'
  import VerticalLineChartItem from '../widget/util/VerticalLineChartItem'
  import ChartDescriptionItem from '../widget/util/ChartDescriptionItem'
  import {common} from '../../mixin/mixin.js'

  export default {
    name: 'StatusWidget',
    mixins: [common],
    components: {BFormInput, DefaultContainerScroll, DoughnutChart, ChartDescriptionItem, VerticalLineChartItem},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        totalCount: 0,
        dataKeys: {}
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      initData() {
        this.totalCount = 0
        this.dataKeys = {data: [
            {key: 0, keyAlias: this.$t('widgets.status.description_short_good'), value: 0, percent: 0, color: this.getColor('green_color')},
            {key: 1, keyAlias: this.$t('widgets.status.description_short_medium'), value: 0, percent: 0, color: this.getColor('blue_color')},
            {key: 2, keyAlias: this.$t('widgets.status.description_short_medium2'), value: 0, percent: 0, color: this.getColor('yellow_color')},
            {key: 3, keyAlias: this.$t('widgets.status.description_short_bad'), value: 0, percent: 0, color: this.getColor('red_color')}
          ]}
      },
      fetchData() {
        this.initData()
        rest.get('/widget/status', this.getParamsForGroupFilter()).then(response => {
          this.fillQueryData(response.data)
        })
          .catch(error => {
            console.log(error)
          })
      },
      fillQueryData(arr) {
        if (arr) {
          for (const val of arr) {
            this.totalCount += val.count
            if (val.status == 'WORKING') {
              this.dataKeys.data[0].value = val.count
            } else if (val.status == 'WAREHOUSE') {
              this.dataKeys.data[1].value = val.count
            } else if (val.status == 'CHECKING') {
              this.dataKeys.data[2].value = val.count
            } else if (val.status == 'FIXING') {
              this.dataKeys.data[3].value = val.count
            }
          }

          for (const item of this.dataKeys.data) {
            item.percent = Math.round(item.value / this.totalCount * 100)
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .current-widget {
    width: 100%;
    height: 100%;
  }

  .main_header {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: get-size(24);
  }
  .big {
    display: inline-block;
    width:80%;
    font-size: get-size(22);
    text-align: left;
  }
  .small {
    display: inline-block;
    width:20%;
    font-size: get-size(14);
    text-align: right;
  }

  .chart-content {
    margin-top: get-size(15);
    width: 100%;
    margin-bottom: get-size(20);
  }

  .data-content {
    padding-top: get-size(20);
  }


</style>
