<template>
  <div class="device-filter-container">
    <div class="controller">
      <div v-if="filterIsPresent">
        <b-button   v-on:click="clearFilter()" class="action-btn">{{$t(`filter.clearFilter`)}}</b-button>
      </div>
      <div>
        <span class="filter-text" v-on:click="filtersOpen=!filtersOpen" >{{$t(`filter.filterText`)}}</span>
        <b-icon-arrow-up class="filterRow" v-on:click="filtersOpen=false" v-if="filtersOpen" v-b-popover.hover.top="{ customClass: 'popover-hover', content: $t(`filter.closeFilterHover`)}"></b-icon-arrow-up>
        <b-icon-arrow-down class="filterRow"  v-on:click="filtersOpen=true" v-if="!filtersOpen" v-b-popover.hover.top="{ customClass: 'popover-hover', content: $t(`filter.openFilterHover`)}"></b-icon-arrow-down>
      </div>
    </div>
    <div class="filters" v-show="filtersOpen">
      <div class="filter">
        <div class="filter-title" v-b-popover.hover.top="{ customClass: 'popover-hover', content: $t(`filter.simpleSearchHover`)}">
          <span>{{$t(`filter.simpleSearchTitle`)}}</span>
        </div>
        <simple-filter-widget :filterVal="deviceFilter.simpleSearch" class="simpleSelectWidget"
                              v-show="showSimpleSearch"
                              @select="val=>selectDeviceFilter(val)"
                              @clear="selectDeviceFilter(null)">

        </simple-filter-widget>
      </div>
      <!-- error widget filter -->
      <div class="filter">
        <div class="filter-title" >
          <span>{{$t(`widgets.error.title_big`)}}</span>
        </div>
        <single-select-filter-widget :filter-val="deviceFilter.errorWidgetFilter" class="simpleSelectWidget"
                              :options="ERROR_WIDGET_FILTER_VALUES"
                              v-show="showSimpleSearch"
                              @select="val=>selectErrorWidgetFilter(val)"
                              @remove="selectErrorWidgetFilter(null)"
                              >

        </single-select-filter-widget>
      </div>
      <!-- battery charge widget filter -->
      <div class="filter">
        <div class="filter-title" >
          <span>{{$t(`widgets.battery_charge.title_big`)}}</span>
        </div>
        <single-select-filter-widget :filter-val="deviceFilter.batteryChargeFilter" class="simpleSelectWidget"
                                     :options="POWER_LEVEL_WIDGET_FILTER_VALUES"
                                     v-show="showSimpleSearch"
                                     @select="val=>selectBatteryChargeWidgetFilter(val)"
                                     @remove="selectBatteryChargeWidgetFilter(null)"
        >
        </single-select-filter-widget>
      </div>
      <!-- connection widget filter -->
      <div class="filter">
        <div class="filter-title" >
          <span>{{$t(`widgets.connection.title_big`)}}</span>
        </div>
        <single-select-filter-widget :filter-val="deviceFilter.connectionWidgetFilter" class="simpleSelectWidget"
                                     :options="CONNECTION_WIDGET_FILTER_VALUES"
                                     v-show="showSimpleSearch"
                                     @select="val=>selectConnectionWidgetFilter(val)"
                                     @remove="selectConnectionWidgetFilter(null)"
        >
        </single-select-filter-widget>
      </div>
    </div>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import _debounce from 'lodash/debounce'
  import SimpleFilterWidget from '../common/SimpleFilterWidget'
  import SingleSelectFilterWidget from '../common/SingleSelectFilterWidget'
  import {ERROR_WIDGET_FILTER_VALUES, POWER_LEVEL_WIDGET_FILTER_VALUES, CONNECTION_WIDGET_FILTER_VALUES} from '../../apiTypeConstant'


  export default {
    name: 'IotDeviceFilterContainer',
    components: {SingleSelectFilterWidget, SimpleFilterWidget},
    props: {
      name: {
        type: String,
        default: ''
      },
      showSimpleSearch: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        ERROR_WIDGET_FILTER_VALUES,
        POWER_LEVEL_WIDGET_FILTER_VALUES,
        CONNECTION_WIDGET_FILTER_VALUES,
        filtersOpen: false,
        isLoading: false
      }
    },
    mounted() {
    },
    created() {
    },
    computed: {
      currentTab () {
        return this.$store.getters.CURRENT_APP_TAB
      },
      filterIsPresent() {
        return this.$store.getters.DEVICE_FILTER_IS_PRESENT
      },
      deviceFilter() {
        return this.$store.getters.DEVICE_FILTER
      }
    },
    watch: {
      currentTab: {
        handler: function (newVal, oldVal) {
          this.filtersOpen = this.filterIsPresent
        }
      }
    },
    methods: {
      clearFilter() {
        this.$store.dispatch('CLEAR_DEVICE_FILTER')
        this.filtersOpen = false
      },

      selectDeviceFilter(val) {
        this.$store.dispatch('SAVE_SIMPLE_SEARCH_DEVICE_FILTER', val)
      },

      selectErrorWidgetFilter(val) {
        if (val) {
          this.$store.dispatch('SAVE_ERROR_WIDGET_DEVICE_FILTER', val.value)
        } else {
          this.$store.dispatch('SAVE_ERROR_WIDGET_DEVICE_FILTER', null)
        }
      },

      selectBatteryChargeWidgetFilter(val) {
        if (val) {
          this.$store.dispatch('SAVE_BATTERY_CHARGE_WIDGET_DEVICE_FILTER', val.value)
        } else {
          this.$store.dispatch('SAVE_BATTERY_CHARGE_WIDGET_DEVICE_FILTER', null)
        }
      },

      selectConnectionWidgetFilter(val) {
        if (val) {
          this.$store.dispatch('SAVE_CONNECTION_WIDGET_DEVICE_FILTER', val.value)
        } else {
          this.$store.dispatch('SAVE_CONNECTION_WIDGET_DEVICE_FILTER', null)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .filter-text {
    cursor: pointer;
    margin-right: get-size(20);
  }
  .device-filter-container {
    background: none !important;
    padding-left: get-size(5);
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding-top: get-size(10);
    padding-bottom: get-size(10);
    border-radius: get-size(8);
  }
  .controller {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-right: get-size(5);
    margin-bottom: get-size(5);
  }
  .filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filter {
    width: 24%;
    min-width: 24%;
    margin-right: get-size(0);
    margin-right: get-size(5);
    margin-bottom: get-size(5);

    display: block;
    padding-top: get-size(10);
    padding-bottom: get-size(10);
    cursor: pointer;
  }

  .filter-title {
    padding-left: get-size(3);
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: get-size(16);
    opacity: 0.8;
  }

  .filterRow {
    cursor: pointer;
  }

  @media (max-width: 850px) {
    .filter {
      width: 32%;
      min-width: 32%;
    }
  }

  @media (max-width: 640px) {
    .filter {
      width: 49%;
      min-width: 49%;
    }
  }

  @media (max-width: 400px) {
    .filter {
      width: 96%;
      min-width: 96%;
    }
  }
</style>
