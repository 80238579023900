<template>
  <div class="simple-select">
    <input type="text"  :value="filterVal" v-on:input="this.debouncedSearchObject" :placeholder="$t(`widgets.select.search`)" >

    <i v-if="filterVal" @mousedown.prevent.stop="clear" class="multiselect__tag-icon">
    </i>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import _debounce from 'lodash/debounce'

  export default {
    name: 'SimpleFilterWidget',
    props: {
      filterVal: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isLoading: false,
        textNoResult: this.$t('widgets.select.objectTextNoResult'),
        filterValue: ''
      }
    },
    mounted() {
    },
    created() {
      this.debouncedSearchObject = _debounce(this.searchObjects, 1000)
    },
    methods: {
      searchObjects (e) {
        this.$emit('select', e.target.value)
      },
      clear() {
        this.$emit('clear')
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .main {

  }

  .multiselect__tag-icon {
    top: get-size(10);
    right: get-size(5);
    line-height: get-size(24);
    width: get-size(20);
  }

  .multiselect__tag-icon:hover {
    background: none !important;
  }

  .simple-select {
    position: relative;
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      outline: none;
    }
    input:focus {
      outline: none;
    }
  }

</style>
