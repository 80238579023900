<template>
  <div class="main">
      <multiselect
        v-model="selectedValues"
        :deselect-label="$t(`widgets.select.delete`)"
        :selectLabel="$t(`widgets.select.selectItem`)"
        :selectedLabel="$t(`widgets.select.selectedLabel`)"
        track-by="sigfoxId"
        :options="options"
        :searchable="true"
        :multiple="true"
        :loading="isLoading"
        :internal-search="false"
        :allow-empty="true"
        :close-on-select="false"
        :clear-on-select="false"
        :options-limit="10"
        :limitText="limitText"

        :limit="3"
        :placeholder="$t(`widgets.select.meterPlaceholder`)"
        @input="(value) => $emit('input', value)"
        @search-change="(value) => {if (value && !isLoading) {this.debouncedSearchMeter(value)}; }"
        @close="close"
        @remove="remove">

        <template #noResult>
          <div v-html="textNoResult"></div>
        </template>

        <template #noOptions> <div v-html="''"></div></template>

        <template slot="option" slot-scope="props">
          <div> {{ props.option.sigfoxId }} ({{props.option.personalAccount}})</div>
        </template>

        <template slot="tag" slot-scope="{ option, remove, index }">
            <span class="multiselect__tag" :key="index">
                                  <span v-text="option.sigfoxId"></span>
                                  <i aria-hidden="true" tabindex="1" @keydown.enter.prevent="remove(option)"  @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
            </span>
        </template>

        <template slot="caret">
          <div class="lupa">
            <b-icon icon="search"></b-icon>
          </div>
        </template>

      </multiselect>
  </div>
</template>

<script>

  import {rest, initCurrentUser} from '../../api'
  import Multiselect from 'vue-multiselect'
  import _debounce from 'lodash/debounce'

  export default {
    name: 'MeterSelectWidget',
    components: {Multiselect},
    props: {
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isLoading: false,
        textNoResult: this.$t('widgets.select.textNoResult'),
        selectedValues: [],
        options: []
      }
    },
    mounted() {
    },
    created() {
      this.debouncedSearchMeter = _debounce(this.searchMeters, 1000)
    },
    methods: {
      close() {
        this.$emit('select', this.selectedValues)
      },
      remove(element) {
        this.selectedValues = this.selectedValues.filter(el => el.sigfoxId !== element.sigfoxId)
        this.$emit('select', this.selectedValues)
      },
      limitText(count) {
        return  '+ ' + count
      },
      searchMeters (query) {
        this.isLoading = true
        this.options = []
        rest.get('/devices/find',  {
          params: {
            search: query.toString().trim()
          }
        }).then(response => {
          this.options = response.data
          this.isLoading = false
        })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }

    }
  }
</script>

<style lang="scss" scoped>
  /* Custom Styles */
  @import "../../assets/scss/main";

  .main {

  }

  .lupa {
    color: #666666;
    right: get-size(1);
    top: get-size(13);
    position: absolute;
    z-index: 20;
    font-size: get-size(20);
  }

</style>
